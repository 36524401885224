import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { motion } from 'framer-motion';
import DeviceType from './DeviceType/DeviceType';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import LiveBouquet from './LiveBouquets/LiveBouquet';
import ModalWarning from './ModalWraning.jsx/ModalWarning';
import { useTranslation } from 'react-i18next';
import Finish from './Finish/Finish';
import Notification from './Notification/Notification';
import WarningNotification from './Notification/WarningNotification';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setActiveStep } from '../../Redux/Slices/CheckoutSlice';
import { useLocation } from 'react-router-dom';

const Checkout = () => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const activeStep = useSelector(state => state.checkout.activeStep);
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const payParam = urlParams.get('pay');
  useEffect(() => {
    if (payParam === 'true') {
      dispatch(setActiveStep(3));
    } else if ((payParam === 'false')) {
      dispatch(setActiveStep(2))
    }
  }, [payParam, dispatch]);

  // Importer les données du Device Type
  const m3uPassword = useSelector((state) => state.checkout.m3u_password);
  const m3uUserName = useSelector((state) => state.checkout.m3u_userName);
  const macMacAddress = useSelector((state) => state.checkout.mac_mac_adress);
  const activeCode = useSelector((state) => state.checkout.activeCode_ActiveCode);
  const vodBouquetData = useSelector((state) => state.checkout.vodBouquetData);
  const seriesBouquetData = useSelector((state) => state.checkout.seriesBouquetData);
  const liveBouquetData = useSelector((state) => state.checkout.liveBouquetData);
  const liveBouquet = useSelector((state) => state.checkout.liveBouquet);
  const seriesBouquet = useSelector((state) => state.checkout.seriesBouquet);
  const vodBouquet = useSelector((state) => state.checkout.vodBouquet);
  const token = useSelector((state) => state.auth.token);

  const steps = [
    { label: t('Checkout.Steps.DeviceType'), component: <DeviceType /> },
    { label: t('Checkout.Steps.LiveBouquets'), component: <LiveBouquet /> },
    { label: t('Checkout.Steps.PayementMethods'), component: <PaymentMethod /> },
    { label: t('Checkout.Steps.Finish'), component: <Finish /> },
  ];

  const handleNext = () => {
    if (activeStep === 0) {
      if (m3uUserName || m3uPassword || macMacAddress || activeCode) {
        dispatch(setActiveStep(activeStep + 1))
      } else {
          if ((!m3uPassword && !m3uUserName) || !macMacAddress || !activeCode) {
            openConfirmation(t('Checkout.DeviceType.err'));
          }
      }
    } else if (activeStep === 1) {
      if (vodBouquetData && seriesBouquetData && liveBouquetData) {
        dispatch(setActiveStep(activeStep + 1))
      } else {
        openConfirmation(t('Checkout.LiveBouquets.err'));
      }
    } else if (activeStep === 2) {
      if (!token) {
        openConfirmation(t('Checkout.PayementMethods.err'));
      }else {
        dispatch(setActiveStep(activeStep + 1))
      }
    }
    else {
      navigate('/mySubscribtion')
    }
  };

  // Notification part
  const [notification, setNotification] = useState(false);
  const [msgNotification, setMsgNotification] = useState('');
  const [warningNotfication , setWraningNotification] = useState(false)
  const [msgWraningNotification , setMsgWraningNotification] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      let newNotification = '';
      let WarningNotification = '';
  
      if ((m3uPassword && m3uUserName) || macMacAddress || activeCode) {
        setNotification(true);
        newNotification = t('Notification.DeviceType');
      }
      if (liveBouquet && seriesBouquet && vodBouquet) {
        setNotification(true);
        newNotification = t('Notification.LiveBouquets');
      }
      if (activeStep === 2 && !token) {
        setWraningNotification(true)
        WarningNotification = t("Notification.auth")
      }
  
      if (newNotification) {
        setMsgNotification(newNotification);
      } else {
        setNotification(false);
      }

      if (WarningNotification) {
        setMsgWraningNotification(WarningNotification)
        setNotification(false);
      }else {
        setWraningNotification(false)
      }
    }, 100);
  
    return () => clearInterval(interval);
  }, [activeStep, m3uPassword, m3uUserName, macMacAddress, activeCode, vodBouquet, seriesBouquet, liveBouquet,handleNext]);  

  const handleBack = () => {
    if (activeStep > 0) {
      dispatch(setActiveStep(activeStep - 1))
    } else {
      dispatch(setActiveStep(0));
    }
  };

  // Modal confirmation
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [msg, setMsg] = useState();
  const openConfirmation = (message) => {
    setMsg(message);
    setConfirmationOpen(true);
  };
  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };

  // De gauche à droite
  const scrollFromLeftVariants = {
    hidden: { x: -3000 },
    visible: { x: 0, transition: { type: 'tween', duration: 0.8 } },
  };

  return (
    <>
      {notification && <Notification msg={msgNotification} />}
      {warningNotfication && <WarningNotification msg={msgWraningNotification}/>}
  
      <motion.div initial='hidden' animate='visible' variants={scrollFromLeftVariants}>
        <ModalWarning isOpen={isConfirmationOpen} onBack={closeConfirmation} Msg={msg} />
        <div className='md:mx-[100px] md:my-[222px] lg:my-[100px] mx-2 my-20'>
          <div className='rounded-md bg-slate-100 py-10 px-5 my-5 text-center'>
            <h1 className='text-xl'>
              <span className='text-slate-900'>{t('Checkout.title')}</span> 👋
            </h1>
            <p className='text-slate-500'>{t('Checkout.description')}</p>
          </div>
          <Box>
         {/* Afficher la liste horizontale des étapes */}
         <ol className="flex flex-col md:flex-row list-none w-full mx-auto mb-5 justify-center items-center">
            {steps.map((step, index) => (
              <li key={index} className={`flex md:my-0 my-1 items-center mr-4 ${index === activeStep ? 'font-bold text-Mybg' : 'text-slate-500'}`}>
                <span className='mr-2'>{index + 1}.</span> {step.label}
              </li>
            ))}
          </ol>

          {/* Contenu de l'étape actuelle */}
          {steps[activeStep].component}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <button
                className='bg-Mybg hover:bg-MybgHover transition-all duration-500 rounded-md px-5 py-2 text-white'
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t('Checkout.button.back')}
              </button>
              <Box sx={{ flex: '1 1 auto' }} />
              <button
                className='bg-Mybg hover:bg-MybgHover transition-all duration-500 rounded-md px-5 py-2 text-white'
                onClick={handleNext}
                disabled={payParam === 'true' ? false : true}
              >
                {activeStep === steps.length - 1 ? t('Checkout.button.finish') : t('Checkout.button.next')}
              </button>
            </Box>
          </Box>
        </div>
      </motion.div>
    </>
  );
}

export default Checkout;