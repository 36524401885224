import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion';
import Loading from '../../Assets/Loading/Loading';
import LoadingImg from '../../Assets/Pics/loading_img.png'
import Refresh from '../AssetsAdmin/Refresh';
import ModalConfirmDelete from './ModalConfirmDelete';
import ModalUpdatePrice from './ModalUpdatePrice';
import { useSelector } from 'react-redux';
import UpdateDescriptionService from './UpdateDescriptionService';

const Packages = () => {
    const {id} = useParams()

    const [packages , setPackage] = useState([])
    const [serviceName , setServiceName] = useState([])
    const [loading , setLoading] = useState(false)

    const fetshPackages = async () =>{
        try{
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/packages/packages-by-service/${id}`)
            if(response.data && response.data.packages.length > 0){
                setPackage(response.data.packages)
                setServiceName(response.data.packages[0].serviceId.name)
            }
        }catch (error) {
            console.log('error lors du excution : ', error)
        } finally{
            setLoading(false)
        }
    }

    const token_admin = useSelector((state) => state.admin.token_admin);

    const enablePackage = async (id) => {
        try{
            setLoading(true)
            const response = await axios.patch(`${process.env.REACT_APP_SERVER}/packages/enable/${id}`, {} , { 
                headers: {
                    'Authorization' : `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            })
            if(response.data){
                setRefesh(refresh + 1)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const scrollFromLeftVariants = {
        hidden: { x: -3000 },
        visible: { x: 0, transition: { type: 'tween', duration: 0.8 } },
    };
    
    // Confirmation Model
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [packageName , setPackageName] = useState();
    const [packageId , setPackageId] = useState()

    const openConfirmation = ( packageName , packageId ) => {
        setPackageName(packageName)
        setPackageId(packageId)
        setConfirmationOpen(true);
    };
    
    const closeConfirmation = () => {
        setConfirmationOpen(false);
    };
    
    const handleConfirm = () => {
        closeConfirmation();
    };

    const [refresh, setRefesh] = useState(0);

    const refereshData = async () => {
        await setRefesh(prevLoading => prevLoading + 1);
    };

    useEffect(() => {
        fetshPackages();
    }, [refresh]);

    //Open Modal Update Price
    const [isUpdatePriceOpen, setUpdatePriceOpen] = useState(false);
    
    const openUpdatePriceModal = ( packageName , packageId ) => {
        setPackageName(packageName)
        setPackageId(packageId)
        setUpdatePriceOpen(true);
    };
        
    const closeUpdatePrice = () => {
        setUpdatePriceOpen(false);
    };
    
    const handleUpdatePrice = () => {
        closeUpdatePrice();
    };
    //Open Modal Update Description service
    const [isUpdateDescriptionOpen , setIsUpdateDescriptionOpne] = useState(false)

    const openUpdateDescription = ( ) => {
        setIsUpdateDescriptionOpne(true)
    }

    const closeUpdateDescription = () => {
        setIsUpdateDescriptionOpne(false);
    };

    const handleUpdateDescription = () => {
        closeUpdateDescription();
    };

    //Convert duration from day to month , year ..
    const convertDuration = (duration) => {
        if (duration >= 365) {
            // Convertir en années
            const years = Math.floor(duration / 365);
            return years === 1 ? `${years} year` : `${years} years`;
        } else if (duration >= 30) {
            // Convertir en mois
            const months = Math.floor(duration / 30);
            return months === 1 ? `${months} month` : `${months} months`;
        } else {
            return duration === 1 ? `${duration} day` : `${duration} days`;
        }
    };

  return (
    <div className='flex mx-auto container flex-col '>
        <ModalConfirmDelete isOpen={isConfirmationOpen} onClose={closeConfirmation}   onConfirm={handleConfirm} packageName={packageName} id={packageId} refereshData={refereshData} />
        <ModalUpdatePrice isOpen={isUpdatePriceOpen} onClose={closeUpdatePrice}   onConfirm={handleUpdatePrice} packageName={packageName} id={packageId} refereshData={refereshData} />
        <UpdateDescriptionService isOpen={isUpdateDescriptionOpen} onClose={closeUpdateDescription} onConfirm={handleUpdateDescription} serviceId={id}/>
        <div className='mt-10 md:text-start text-center'>
            <h1 className='font-medium text-xl'>Mega Package Management <span className=''>{serviceName}</span></h1>
            <p className="text-md text-slate-500">Perform all operations (Enable , Disable) for "<span className='font-semibold'>{serviceName}</span>" service packages with ease</p>
        </div>
        <div className='flex justify-center md:justify-end items-center mx-4'>
            {/* <button onClick={refereshData} className='bg-Mybg hover:bg-MybgHover mx-1 items-center justify-center rounded-md py-2 px-5 shadow-md my-2 inline-flex'>
                <Refresh />
            </button> */}
            <button onClick={openUpdateDescription} className='bg-Mybg text-white hover:bg-MybgHover mx-1 items-center justify-center rounded-md py-2 px-5 shadow-md my-2 inline-flex'>
                Description Service
            </button>
        </div>
        <motion.div initial='hidden' animate='visible' variants={scrollFromLeftVariants}>
            <div className='flex justify-center items-center mx-auto'>
                <div className='flex flex-wrap'>
                {
                    loading 
                    ? 
                    <>
                        <Loading />
                        <div className='flex justify-center items-center'>
                            <div className='h-[300px] w-[300px]'>
                                <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                            </div>
                        </div>
                    </>
                  : 
                  <>
                  {packages.map((pac) => (
                        <div key={pac._id} className="w-full md:w-1/2 lg:w-1/3 md:p-2 py-2 hover:scale-y-105 md:hover:scale-105 transition-all duration-500 mx-2 md:mx-0">
                            <div className="cursor-pointer border-2 border-slate-200 rounded-md px-2 py-2">
                                <h1 className="text-center text-4xl px-10 text-white md:px-20 py-14 bg-BgService rounded-md hover:bg-BgServiceHover transition-all duration-500">
                                    {pac.serviceId.name}
                                </h1>
                                <div className="my-5 flex items-center">
                                    <div className='w-1/2'>
                                        <span className="text-xl text-slate-500">{pac.serviceId.name}</span>&nbsp;&nbsp;
                                        <span className='text-sm'>{pac.name}</span>
                                    </div>
                                    <div className='w-1/2 flex justify-end items-center'>
                                        <span className="">{pac.etat}</span>
                                    </div>
                                </div>
                            <div className="my-1 flex justify-end">
                                <span className='text-lg'>{pac.price} {pac.currency}/{convertDuration(pac.duration)}</span>
                            </div>
                            <div className="mt-5 mx-auto flex justify-center items-center border-t-2 border-slate-300 rounded-xl py-1">
                                {
                                    pac.etat == "Available" ? (
                                        <button onClick={()=>{openConfirmation(pac.name , pac._id)}}  className='bg-slate-50 justify-center items-center py-1.5 px-2 border-2 border-slate-200 hover:bg-slate-100 rounded-md w-1/2 inline-flex mx-1'>
                                            <p className='text-red-500'>Disable</p>
                                        </button>
                                    ) : (
                                        <button onClick={()=>{enablePackage(pac._id)}}  className='bg-slate-50 justify-center items-center py-1.5 px-2 border-2 border-slate-200 hover:bg-slate-100 rounded-md w-1/2 inline-flex mx-1'>
                                            <p className='text-green-500'>Enable</p>
                                        </button>
                                    )
                                }
                                <button onClick={()=>{openUpdatePriceModal(pac.name , pac._id)}}  className='bg-slate-50 justify-center items-center py-1.5 px-2 border-2 border-slate-200 hover:bg-slate-100 rounded-md w-1/2 inline-flex mx-1'>
                                    <p className='text-yellow-500'>Update</p>
                                </button>
                            </div>
                            </div>
                        </div>
                    ))
                  }
                  </>
                }
                </div>
            </div>
        </motion.div>
    </div>
  )
}

export default Packages