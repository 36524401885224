import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Update from '../../../Assets/Icons/Update';
import Details from '../../../Assets/Icons/Details';
import ModalDetailsSubscribtion from './ModalDetailsSubscribtion/ModalDetailsSubscribtion'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Loading from '../../../Assets/Loading/Loading';
import LoadingImg from '../../../Assets/Pics/loading_img.png'
import Vide from '../../../Assets/Pics/Vide.png'
import TableData from './TableData'

const MySubscribtion = () => {

  const scrollFromLeftVariants = {
    hidden: { x: -2000 },
    visible: { x: 10, transition: { type: 'tween', duration: 0.8 } },
  };

  //Modal Details
  const [name , setName] = useState();
  const [detailsOpen , setDetailsOpen] = useState(false)
  const [paymentStatus , setPaymentStatus] = useState()
  const [Duration , setDuration] = useState()
  const [SubDate , setSubDate] = useState()
  const [deviceType , setDeviceType] = useState()
  const [price , setPrice] = useState()
  const [deviceDetails , setDeviceDetails] = useState({})

  const DetailsModal = (paymentStatus , name , SubDate , deviceType , price , Duration , deviceDetails) =>{
    ToggleActionButton(null)
    setDetailsOpen(true)
    setName(name)
    setPaymentStatus(paymentStatus)
    setDuration(Duration)
    setSubDate(SubDate)
    setDeviceType(deviceType)
    setPrice(price)
    {
      deviceType === 'activeCode' 
      ?
      setDeviceDetails({
        code : deviceDetails.activeCode.code
      })
      :
      deviceType === 'mac' 
      ?
      setDeviceDetails({
        mac : deviceDetails.mac.macAddress
      })
      :
      setDeviceDetails({
        userName : deviceDetails.m3u.userName,
        password : deviceDetails.m3u.password
      })
    }
  }

  const CloseDetails = () =>{
    setDetailsOpen(false)
  }


  const [openMenuId, setOpenMenuId] = useState(null);

  const ToggleActionButton = (id) => {
    if (openMenuId === id) {
      setOpenMenuId(null);
    } else {
      setOpenMenuId(id);
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.map((subscription) => {
          if (subscription.id === id) {
            return { ...subscription, open: true };
          } else {
            return { ...subscription, open: false };
          }
        })
      );
    }
  };

  const navigate = useNavigate()

  //Translation
  const {t} = useTranslation('global')

  //fetsh Subscription

  const [loading , setLoading] = useState(false)
  const [userId , setUserId] = useState(Cookies.get('id') || '')
  const token = useSelector((state) => state.auth.token);
  const [subscriptions, setSubscriptions] = useState([]);

  const fetshSubscription = async () => {
    try {
      setLoading(true);
      
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/subscription/subscription-user`,
        {
          params: { userId },
          headers: {
            'Content-Type': 'application/json',
            'secret_key': process.env.REACT_APP_KEY_BACKEND,
            'Authorization': `Bearer ${token}`,
            'Accept-Language' : localStorage.getItem('language')
          },
        }
      );
    
      if (response.data) {
        setSubscriptions(response.data.subscriptions);
      }
    } catch (error) {
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(()=>{
    fetshSubscription()
  } , [])

  return (
    <div className='container px-5 mx-auto md:px-20 my-20 md:my-[116px]'>
      <ModalDetailsSubscribtion isOpen={detailsOpen} onClose={CloseDetails} name={name} paymentStatus={paymentStatus} Duration={Duration} SubDate={SubDate} deviceType={deviceType} price={price} deviceDetails={deviceDetails}/>
      <div className='mt-20'>
        <h1 className='md:text-start text-center text-2xl'>{t("MySubscribtion.title")}</h1>
        <p className='text-slate-500 text-center md:text-start text-md mt-2'>
          {t("MySubscribtion.description")}
        </p>
      </div>
      <div className='flex'>
        <div className='my-5 text-center md:text-start mx-auto md:mx-0'>
          <span className='font-md'>{t("MySubscribtion.SortBy")} :</span>
          <button className='py-1 px-2 border-slate-100 border-2 rounded-md mx-1 transition-all duration-500 hover:scale-105'>{t("MySubscribtion.Recent")}</button>
          <button className='py-1 px-2 border-slate-100 border-2 rounded-md mx-1 transition-all duration-500 hover:scale-105'>{t("MySubscribtion.Ancient")}</button>
        </div>
      </div>
      <div className='block md:hidden lg:hidden'>
      {  loading 
            ? 
                <>
                    <Loading />
                    <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                        <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                    </div>
                </>
            : 
      <div className='mt-5 pr-5 flex flex-wrap'>
            {
              subscriptions && subscriptions.length > 0 ? (
              subscriptions.map((item)=>(
                    <motion.div key={item._id} className='w-full flex-grow flex-shrink-0 lg:w-1/4 my-1 cursor-pointer shadow-lg rounded-md border-slate-300 border-2 py-5 px-5 transition-all duration-500 mx-1' initial='hidden' animate='visible' variants={scrollFromLeftVariants}>
                        <div className='flex items-center'>
                            <div className='w-1/2 flex justify-start flex-col'>
                                <h1 className='text-lg inline-flex items-center capitalize'>
                                  <span className='mx-1'>{ item.paymentStatus === 'pending' ? <div className='w-2 h-2 bg-yellow-500 rounded-full p-2'></div> : item.paymentStatus === 'success' ? <div className='w-2 h-2 bg-green-500 rounded-full p-2'></div> : <div className='w-2 h-2 bg-red-500 rounded-full p-2'></div>}</span>
                                  {item.paymentStatus}  
                                </h1>
                                <p className='text-slate-500 '>{item.createdAt.split("T")[0]}</p>
                            </div>
                            <div className='w-1/2 flex justify-end'>
                                <span className='bg-Mybg rounded-md text-white py-2 px-2'>{item.packageId.price}</span>
                            </div>
                        </div>
                        <div className='flex justify-center items-center my-5 flex-col'>
                            <h1 className='text-xl'>{item.packageId.name}</h1>
                            <p className=' text-slate-500 my-2'>{item.packageId.duration}</p>
                        </div>
                        <div className='flex justify-center items-center border-t-2 border-slate-200 py-2 mt-10 '>
                            {/* Update */}
                            <button className='flex justify-center items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700' onClick={()=>{navigate(`updateSubscribtion/${item._id}`)}}><span className='mr-3 text-yellow-500 hover:text-yellow-400 rounded-md p-1'><Update /></span>{t("MySubscribtion.table.Update")}</button>
                            {/* Details */}
                            <button className='flex justify-center items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700' onClick={()=>{DetailsModal(item.paymentStatus , item.packageId.name , item.createdAt.split("T")[0] , item.deviceType , item.packageId.price , item.packageId.duration , item.deviceDetails)}}><span className='mr-3 text-green-500 hover:text-green-400 rounded-md p-1'><Details /></span>{t("MySubscribtion.table.Details")}</button>
                        </div>
                    </motion.div>
                ))
              )
            :
            (
            <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
              <img src={Vide} alt='Vide' className='h-full w-full object-contain' />
            </div>
            )
            }
        </div>
    }
    </div>
    <div className='hidden md:block lg:block'>
        <TableData />
    </div>
    </div>
  );
};

export default MySubscribtion;