import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Error from '../../Assets/Alert/Error';
import { motion } from 'framer-motion';

const UpdateDescriptionService = ({ isOpen , onClose , serviceId }) => {
    const handleCancel = () => {
        onClose();
    };
    const token_admin = useSelector((state) => state.admin.token_admin);
    const [errMsg, setErrorMsg] = useState('')
    const [description , setDescription] = useState()

    const updateServiceDescription = async () => {
        try {
            const requestData = {
                description: description,
            };
    
            const response = await axios.put(
                `${process.env.REACT_APP_SERVER}/service/update-service-description/${serviceId}`,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token_admin}`,
                        'Accept-Language': localStorage.getItem('language'),
                    },
                }
            );
    
            if (response.data) {
                onClose();
            }
        } catch (error) {
            setErrorMsg(error.response.data.error);
        }
    };  
  return (
    <motion.div 
    className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    initial={{ opacity: 0 }}
    animate={{ opacity: isOpen ? 1 : 0 }}
    transition={{ duration: 0.3 }}
    >
        <div className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px] w-full">
                <div className='my-2'>
                    <label className="block mb-2 text-sm font-medium">Description</label>
                    <textarea rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-Mybg focus:border-Mybg" placeholder="Write your Description here..." value={description} onChange={(e)=>{setDescription(e.target.value)}}></textarea>
                </div>
                {
                    errMsg && (
                        <Error  className='my-2' message={errMsg} />
                    )
                }
                <div className="flex justify-end mt-5">
                    <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleCancel}>Close</button>
                    <button className="bg-Mybg hover:bg-MybgHover px-4 py-2 mr-2 rounded text-white" onClick={updateServiceDescription}>Submit</button>
                </div>
            </div>
        </div>
    </motion.div>
  )
}

export default UpdateDescriptionService