import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const PieChart = ({ data, chartId }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartElement = document.getElementById(chartId);

        if (chartElement) {
            // Destroy the previous chart instance if it exists
            if (chartRef.current) {
                chartRef.current.destroy();
            }

            const ctx = chartElement.getContext('2d');

            const chartData = {
                labels: ['Pending', 'Success', 'Failed', 'Success Subscribers'],
                datasets: [{
                    data: [
                        data.pendingPercentage,
                        data.successPercentage,
                        data.failedPercentage,
                        data.successSubscriberPercentage
                    ],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(255, 205, 86, 0.8)',
                        'rgba(54, 162, 235, 0.8)'
                    ],
                }]
            };

            const options = {
                responsive: true,
                maintainAspectRatio: false,
            };

            // Create a new Chart instance and update the chartRef
            chartRef.current = new Chart(ctx, {
                type: 'pie',
                data: chartData,
                options: options,
            });
        } else {
            console.error(`Element with ID "${chartId}" not found in the DOM.`);
        }

        return () => {
            // Cleanup and destroy the chart instance on component unmount
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [data, chartId]);

    return <div id={`${chartId}Container`}><canvas id={chartId} width="200" height="200"></canvas></div>;
}

export default PieChart;