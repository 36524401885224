import React, { useState, useEffect } from 'react';
import Copy from '../../../Assets/Icons/Copy';
import ActiveCodeIcon from '../../../Assets/Icons/ActiveCodeIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { squircle } from 'ldrs'
import Success from '../../../Assets/Icons/Success';
import { useDispatch } from 'react-redux';
import { setActiveCode, setDeviceActiveCode } from '../../../Redux/Slices/CheckoutSlice';
import { setActiveStep } from '../../../Redux/Slices/CheckoutSlice';

const ActiveCode = () => {
  squircle.register()
  const [randomNumbers, setRandomNumbers] = useState([]);
  const [copied, setCopied] = useState(false);
  const [loading , setLoading] = useState(false)
  const activeStep = useSelector(state => state.checkout.activeStep);

  const generateRandomNumbers = () => {
    const constantPrefix = '0000';
    const newNumbers = [];
    
    for (let i = 0; i < 8; i++) {
        newNumbers.push(Math.floor(Math.random() * 10));
    }
    const randomCode = constantPrefix + newNumbers.join('');

    if (randomCode.length !== 12 || !/^\d+$/.test(randomCode)) {
        generateRandomNumbers();
    } else {
        setRandomNumbers(randomCode);
        setCopied(false);
    }
  };

  useEffect(() => {
    generateRandomNumbers();
    const intervalId = setInterval(() => {
      generateRandomNumbers();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleCopyClick = () => {
    const textToCopy = document.getElementById('content-to-copy');
    const range = document.createRange();
    range.selectNode(textToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    try {
      document.execCommand('copy');
      setCopied(true);
    } catch (error) {
      console.error('Erreur lors de la copie dans le presse-papiers', error);
    } finally {
      window.getSelection().removeAllRanges();
    }
  };

  const dispatch = useDispatch();

  const SubmitActiveCode = async () => {
    dispatch(setActiveCode(randomNumbers));
    dispatch(setActiveStep(activeStep + 1))
    dispatch(setDeviceActiveCode())
  };  

  // Translation
  const { t } = useTranslation('global');

  return (
    <div className='flex mt-5 flex-col lg:flex-row items-center'>
      <div className="lg:w-1/2 w-full flex flex-col lg:mx-10 my-2">
        <div className="mb-4 flex items-center">
          <div id="content-to-copy" className='bg-MybgHover py-7 w-full flex justify-center rounded-md'><span className='text-white'>{randomNumbers}</span></div>
          <div className='flex flex-col'>
            <button onClick={handleCopyClick} className='ml-2 bg-Mybg hover:bg-MybgHover text-white py-2 px-4 rounded-md my-1'><span className='text-white'>{copied ? <Success /> : <Copy />}</span></button>
            <button onClick={generateRandomNumbers} className="ml-2 bg-Mybg hover:bg-MybgHover text-white py-2 px-4 rounded-md"><ActiveCodeIcon /></button>
          </div>
        </div>
        <button className={`py-2 mt-1 rounded-md text-white bg-Mybg hover:bg-MybgHover`} onClick={SubmitActiveCode}>
          {loading ? <l-squircle size="20" stroke="5" strokeLength="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : t('Checkout.DeviceType.activeCode.button')}
        </button>
      </div>
      <div className="lg:w-1/2 w-full flex items-center mt-5 md:mt-0 lg:mx-10 my-2">
        <p className='text-center md:text-start'>{t('Checkout.DeviceType.activeCode.description')}</p>
      </div>
    </div>
  );
};

export default ActiveCode;