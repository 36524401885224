import React, { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Loading from '../../../Assets/Loading/Loading';

const Finish = () => {
    const [confettiTriggered, setConfettiTriggered] = useState(false);

    const navigate = useNavigate('')

    useEffect(() => {
      if (!confettiTriggered) {
        var duration = 2 * 1000;
        var end = Date.now() + duration;
        var colors = ['#bb0000', '#ffffff'];
        (function frame() {
            confetti({ particleCount: 2, angle: 60, spread: 55, origin: { x: 0 }, colors: colors,});
            confetti({ particleCount: 2, angle: 120, spread: 55, origin: { x: 1 }, colors: colors,});
            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        })();
        setConfettiTriggered(true);
      }
    }, [confettiTriggered]);

    const naviagteToMySubscribtion = () =>{
        navigate('/mySubscribtion')
    }

    //Translation
    const {t} = useTranslation('global');

    //Fetsh Subscription by subId
    const [loading , setLoading] = useState(false)
    const { id } = useParams()
    const [packageName , setPackageName] = useState()
    const [packagePrice , setPackagePrice] = useState()
    const [packageDuration , setPackageDuration] = useState()
    const [deviceType , setDeviceType] = useState()
    const token = useSelector((state) => state.auth.token);

    const fetshSubscriptionBySubId = async () => {
        try{
            setLoading(true)
            const response = await axios.get(process.env.REACT_APP_SERVER + `/subscription/${id}`, {
                headers: {
                  'Content-Type': 'application/json',
                  'secret_key' : process.env.REACT_APP_KEY_BACKEND,
                  'Authorization' : `Bearer ${token}`,
                  'Accept-Language' : localStorage.getItem('language')
                },
            })
            if(response.data){
                setPackageName(response.data.subscription.packageId.name)
                setPackagePrice(response.data.subscription.packageId.price)
                setPackageDuration(response.data.subscription.packageId.duration)
                setDeviceType(response.data.subscription.deviceType)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(()=>{
        fetshSubscriptionBySubId()
    },[])

    return (
        <div className='p-4 bg-slate-100 rounded-md my-10 px-5'>
            <div className='flex justify-center items-center flex-col my-10'>
                <h1 className='text-xl capitalize'>{t('Checkout.Finish.title')}</h1>
                <p className='text-slate-500'>{t('Checkout.Finish.description')}</p>
            </div>
            {
                loading 
                ?
                    <Loading/>
                :
            <div className='flex justify-center items-center flex-col my-4'>
                <h1 className='my-2 text-lg'>Subscription Info</h1>
                <div>
                    <li>Package Name : <span className='text-Mybg'>{packageName}</span></li>
                    <li>Package Price : <span className='text-Mybg'>{packagePrice}</span></li>
                    <li>Package Duration : <span className='text-Mybg'>{packageDuration}</span></li>
                    <li>Device Type : <span className='text-Mybg'>{deviceType}</span></li>
                </div>
            </div>
            }
            <div className='flex justify-center items-center my-6'>
                <button onClick={naviagteToMySubscribtion}  className='bg-Mybg rounded-md px-4 py-2 hover:bg-MybgHover text-white capitalize'>
                    {t('Checkout.Finish.button')}
                </button>
            </div>
        </div>
    );
};

export default Finish;
