import React from 'react'
import { squircle } from 'ldrs'

const Loading = () => {
    squircle.register()
  return (
    <div className='fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center'>
        <span className='flex justify-center items-center'>
        <l-squircle
            size="37"
            stroke="5"
            stroke-length="0.15"
            bg-opacity="0.1"
            speed="0.9" 
            color="black" 
        ></l-squircle>
        </span>
    </div>
  )
}

export default Loading