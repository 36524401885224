import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import AuthorizeUser from '../../Assets/Icons/AuthorizeUser'
import Banned from '../../Assets/Icons/Banned';
import Picture from '../../Assets/Icons/Picture'
import { useSelector } from 'react-redux';
import Loading from '../../Assets/Loading/Loading';
import LoadingImg from '../../Assets/Pics/loading_img.png';
import AddPicture from './AddPicture'

const Service = () => {
  const [serviceData, setServiceData] = useState([]);
  const [loading, setLoading] = useState(false)
  const token_superadmin = useSelector((state) => state.superAdmin.token_superadmin);

  const columns = [
    {
      name: 'Service Picture',
      selector: 'ServicePicture',
      cell: row => <img src={`${process.env.REACT_APP_SERVER}/service_picture/${row.ServicePicture}`} alt={row.name} style={{ width: '30px', height: '30px' }} />,
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Active',
      cell: (row) => (
        <span className={!row.active ? 'text-red-500' : 'text-green-500'}>
          {row.active ? 'Active' : 'Disabled'}
        </span>
      ),
    },
    {
      name: 'Package Count',
      selector: 'packageCount',
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center items-center space-x-2">
          {
            !row.active ? (
              <button onClick={()=>{enableSerice(row._id)}}  className='rounded-md text-sm text-green-500 hover:text-green-400 p-1'><AuthorizeUser /></button>
            ) : (
              <button onClick={()=>{disableSerice(row._id)}} className='rounded-md text-sm text-red-500 hover:text-red-400 p-1'><Banned /></button>
            )
          }
          <button onClick={()=>{openConfirmation(row._id)}}  className='rounded-md text-sm text-lime-500 hover:text-lime-400 p-1'><Picture /></button>
        </div>
      ),
    },
  ];

  const fetchService = async () => {
    try {
      setLoading(true);
      const response = await axios.get(process.env.REACT_APP_SERVER + '/service/all');
      if (response.data) {
        setServiceData(response.data);
      }
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchService();
  }, []);

  const enableSerice = async (id) => {
    try {
        const response = await axios.put(process.env.REACT_APP_SERVER + `/superAdmin/enable/${id}`, {} , { 
            headers: {
              'Authorization' : `Bearer ${token_superadmin}`,
              'Accept-Language' : localStorage.getItem('language')
            }
        })
        if(response.data) {
          fetchService();
        }
    } catch (error) {
        console.error(error)
    }
  }
  const disableSerice = async (id) => {
      try {
          const response = await axios.put(process.env.REACT_APP_SERVER + `/superAdmin/disable/${id}`, {} , { 
              headers: {
                'Authorization' : `Bearer ${token_superadmin}`,
                'Accept-Language' : localStorage.getItem('language')
              }
          })

          if(response.data) {
            fetchService();
          }
      } catch (error) {
          console.error(error)
      }
  }

  //Modal picture 
  const [serviceId , setServiceId] = useState()
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const openConfirmation = ( id ) => {
    setConfirmationOpen(true);
    setServiceId(id)
  };
  
  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };
  
  const handleConfirm = () => {
    closeConfirmation();
  };

  return (
    <div className='flex flex-col mx-2 lg:mx-20'>
      <div className='flex md:items-start items-center mt-10 flex-col text-center md:text-start'>
        <h1 className='text-2xl text-start'>Service</h1>
        <p className='text-slate-500'>Service Management Interface.</p>
      </div>
      <AddPicture isOpen={isConfirmationOpen} onClose={closeConfirmation} onConfirm={handleConfirm} id={serviceId} fetchService={fetchService} />
      {
        loading ? (
          <>
            <Loading />
            <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
              <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
            </div>
          </>
        ) : (
          <div className='mt-5'>
            <DataTable
              columns={columns}
              data={serviceData}
              pagination
              sort
              className="border border-gray-300 rounded-lg shadow-lg overflow-hidden"
              striped
              highlightOnHover
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30]}
            />
          </div>
        )
      }
    </div>
  );
};

export default Service;