import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './User/Header/Header';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme/Theme';
import { Provider } from 'react-redux';
import store from './Redux/Store/Store';
import global_en from './translation/en/global.json'
import global_fr from './translation/fr/global.json'
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthAdmin from './Admin/Auth/AuthAdmin';
import { useSelector, Provider as ReduxProvider } from 'react-redux'
import HeaderAdmin from './Admin/HeaderAdmin/HeaderAdmin';
import HeaderSuperAdmin from './SuperAdmin/Header/Header'
import AuthSuperAdmin from './SuperAdmin/Auth/Auth'

i18next.init({
  interpolation : { escapeValue : false },
  lng : "en" ,
  resources : {
    en : {
      global : global_en
    },
    fr : {
      global : global_fr
    }
  }
})
document.title = process.env.REACT_APP_NAME;

const App = () => {
  const token_admin = useSelector((state) => state.admin.token_admin);
  const token_superadmin = useSelector((state) => state.superAdmin.token_superadmin);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18next} >
            <Router>
              <Routes>
                <Route path="/*" element={<Header />} />
                <Route path="/admin/*" element={token_admin ? <HeaderAdmin/> : <AuthAdmin/>} />
                <Route path="/superadmin/*" element={token_superadmin ? <HeaderSuperAdmin/> : <AuthSuperAdmin/>} />
              </Routes>
            </Router>
          </I18nextProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);

reportWebVitals();
