import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ data }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        renderBarChart();
        return () => {
            destroyChart();
        };
    }, [data]);

    const renderBarChart = () => {
        const barChartElement = document.getElementById('barChart');

        if (barChartElement) {
            destroyChart();

            const ctx = barChartElement.getContext('2d');

            const userCountsData = data.userCountsByMonth.map(item => item.count);
            const subscriptionCountsData = data.subscriptionCountsByMonth.map(item => item.count);

            const chartData = {
                labels: data.userCountsByMonth.map(item => item.label),
                datasets: [
                    {
                        label: 'Nombre d\'utilisateurs inscrits',
                        data: userCountsData,
                        backgroundColor: 'rgba(75, 192, 192, 0.8)',
                        borderRadius: 5,
                    },
                    {
                        label: 'Nombre d\'abonnements',
                        data: subscriptionCountsData,
                        backgroundColor: 'rgba(255, 99, 132, 0.8)',
                        borderRadius: 5,
                    },
                ],
            };

            const options = {
                responsive: true,
                maintainAspectRatio: false,
            };

            chartRef.current = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: options,
            });
        } else {
            console.error('Element with ID "barChart" not found in the DOM.');
        }
    };

    const destroyChart = () => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
    };

    return <canvas id="barChart" width="200" height="200"></canvas>;
};

export default BarChart;