import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { motion } from 'framer-motion';

const Featured = () => {
  const images = [
    'Pictures/Paramount.png',
    'Pictures/Bein-Sport.png',
    'Pictures/Universal-Pictures.png',
    'Pictures/Netflix-logo.png',
    'Pictures/Disney-Channel.png',
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const scrollFromLeftVariants = {
    hidden: { x: -3000 },
    visible: { x: 0, transition: { type: 'tween', duration: 0.8 } },
  };

  return (
    <div className="flex justify-center md:flex-row flex-col">
      <motion.div className="flex justify-center items-center mx-auto md:w-full w-[250px]" variants={scrollFromLeftVariants} initial="hidden" animate="visible">
        <Carousel autoPlay infiniteLoop showStatus={false} className='hover:scale-105 transition-all duration-500 md:hidden' showThumbs={false} showArrows={false} interval={3000} selectedItem={currentImageIndex}>
          {images.map((image, index) => (
            <div key={index} className='h-[250px]'>
                <img src={image} alt={`Image ${index}`} className="md:w-[200px]" />
            </div>
          ))}
        </Carousel>
        <div className="hidden md:block container mx-10 transition-all duration-500"> 
          <div className='flex'>
          {images.map((image, index) => (
            <div key={index} className='shadow-lg border-2 border-slate-200 rounded-md mx-1 hover:border-slate-200 transition-all duration-500 hover:scale-105'>
              <img src={image} alt={`Image ${index}`} className="w-full px-2" />
            </div>
          ))}
        </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Featured;