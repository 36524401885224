import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const fetchTheme = createAsyncThunk('theme/fetchTheme', async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_SERVER + '/theme/show');
        return response.data.data;
    } catch (error) {
        throw error;
    }
});

const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        myBgColor: '',
        myBgHoverColor: '',
        status: 'idle',
        loading: true,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchTheme.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        })
        .addCase(fetchTheme.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.myBgColor = action.payload.Mybg;
            state.myBgHoverColor = action.payload.MybgHover;
            state.loading = false;
        })
        .addCase(fetchTheme.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.loading = false;
        });
    },
});

export { fetchTheme };
export default themeSlice.reducer;