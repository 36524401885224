import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Error from '../../../../Assets/Alert/Error';
import { squircle } from 'ldrs';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLatestPath } from '../../../../Redux/Slices/CheckoutSlice';
import { setReseller } from '../../../../Redux/Slices/AuthSlice';

const Paypal = () => {
  squircle.register();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.token);
  // Device Selected
  const device_m3u = useSelector((state) => state.checkout.device_m3u);
  const device_mac = useSelector((state) => state.checkout.device_mac);
  const device_activeCode = useSelector((state) => state.checkout.device_activeCode);
  // Device Payload
  const m3u_password = useSelector((state) => state.checkout.m3u_password); // M3U
  const m3u_userName = useSelector((state) => state.checkout.m3u_userName); // M3U
  const mac_mac_adress = useSelector((state) => state.checkout.mac_mac_adress); // MAC
  const activeCode_ActiveCode = useSelector((state) => state.checkout.activeCode_ActiveCode); // ActiveCode
  // Bouquet Payload
  const liveBouquetData = useSelector((state) => state.checkout.liveBouquetData); // Live Bouquet
  const serieBouquetData = useSelector((state) => state.checkout.serieBouquetData); // Serie Bouquet
  const vodData = useSelector((state) => state.checkout.vodData); // Vode Bouquet
  // User Id
  const [userId, setUserId] = useState(Cookies.get('id') || '');
  // Package Id
  const { id } = useParams();
  // Navigate
  const navigate = useNavigate()
  // Dispatch
  const dispatch = useDispatch();
  // Update status reseller
  const updateStatusReseller = async (idUser) => {
    try {
      const response = await axios.post(process.env.REACT_APP_SERVER + `/reseller/updateResellerStatus/${idUser}` , {} , {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': localStorage.getItem('language')
        }
      });
      if (response.data) {
        dispatch(setReseller(response.data.reseller));
        Cookies.set('Reseller' , response.data.reseller , { expires: 10 })
      }
    } catch (error) {
      console.log(error.response.data.error)
    }
  }

  const handlePayPalPayment = async () => {
    try {
      setLoading(true);
      if (!token) {
        if (id) {
          dispatch(setLatestPath(`/checkout/${id}`));
        }
        navigate('/signIn');
      }else{
      let deviceType = '';
      let deviceDetails = {};

      const m3uDetails = {
        userName: m3u_userName,
        password: m3u_password
      };

      const macDetails = {
        macAddress: mac_mac_adress
      };

      const activeCodeDetails = {
        code: activeCode_ActiveCode
      };

      if (device_m3u) {
        deviceType = 'm3u';
        deviceDetails = m3uDetails; // Utilisez les détails m3u
      } else if (device_mac) {
        deviceType = 'mac';
        deviceDetails = macDetails; // Utilisez les détails mac
      } else if (device_activeCode) {
        deviceType = 'activeCode';
        deviceDetails = activeCodeDetails; // Utilisez les détails de code d'activation
      }

      const requestData = {
        userId: userId,
        packageId: id,
        paymentMethod : "paypal",  
        deviceType: deviceType,
        m3uDetails: deviceType === 'm3u' ? deviceDetails : null,
        macDetails: deviceType === 'mac' ? deviceDetails : null,
        activeCodeDetails: deviceType === 'activeCode' ? deviceDetails : null,
        liveBouquet: liveBouquetData,
        seriesBouquet: serieBouquetData,
        vodBouquet: vodData
      };

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/subscription/create-subscription`,
        requestData,
        {
          headers: {
            'secret_key': process.env.REACT_APP_KEY_BACKEND,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('language')
          }
        }
      );

      if (response.data) {
        await updateStatusReseller(userId)
        await window.open(response.data.link, '_blank');
      } else {
        setError(response.data.message);
      }
    }
    } catch (error) {
      setError(error.response.data.error || 'Une erreur est survenue lors du paiement.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <l-squircle size="20" stroke="5" stroke-length="0.15" bg-opacity="0.1" speed="0.9" color="black"></l-squircle>
      ) : (
        <button type="button" onClick={handlePayPalPayment} disabled={loading}
        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl
        focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium
        rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Payer avec PayPal</button>
      )}
      {error && <Error message={error} />}
    </div>
  );
};

export default Paypal;
