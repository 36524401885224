import React, { useState, useRef } from 'react';
import Close from '../../Admin/AssetsAdmin/Close';
import { squircle } from 'ldrs';
import axios from 'axios';
import Cookies from 'js-cookie';
import Error from '../../Assets/Alert/Error';
import Loading from '../../Assets/Loading/Loading';

const VerfificationSignIn = ({ isOpen , onClose , email }) => {
    squircle.register();
    const handleCancel = () => {
        onClose();
        window.location.reload();
    };
    const [inputs, setInputs] = useState(['', '', '', '', '']);
    const [loading , setLoading] = useState(false);
    const [errMessage , setErrMessage] = useState();

    const inputRefs = useRef([]);

    const handleChange = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index] = value;
    
        if (value !== '' && index < 4) {
            inputRefs.current[index + 1].focus();
        }
    
        setInputs(newInputs);
    
        if (!newInputs.includes('') && newInputs.length === 5) {
            const code = newInputs.join('');
            checkCodeEmail(code);
        }
    };    

    //Check Code & email
    const checkCodeEmail = async (code) => {
        try {
            setLoading(true);
            const response = await axios.post(process.env.REACT_APP_SERVER + `/admin/verify-code?email=${email}&code=${code}` , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': localStorage.getItem('language'),
                },
            });
            if(response.data){
                Cookies.set('token_admin' , response.data.token , { expires: 1 });
                Cookies.set('Admin_Id' , response.data.adminId , { expires: 1 });
                Cookies.set('Admin_Name' , response.data.adminName , { expires: 1 });
                Cookies.set('Admin_Email' , response.data.email , { expires: 1 });
                window.location.reload();
            }
        } catch (error) {
            setErrMessage(error.response.data.message);
            console.error("Err : " , error);
        } finally {
            setLoading(false);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text/plain');
        const pastedChars = pasteData.split('').slice(0, 5);
        const newInputs = [...inputs];

        pastedChars.forEach((char, index) => {
            newInputs[index] = char;
            if (index < 4) {
                inputRefs.current[index + 1].focus();
            }
        });

        setInputs(newInputs);

        if (!newInputs.includes('') && newInputs.length === 5) {
            const code = newInputs.join('');
            checkCodeEmail(code);
        }
    };

    return (
        <div className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px] flex flex-col">
                <div className='flex justify-end'>
                    <button onClick={handleCancel}>
                        <Close />
                    </button>
                </div>
                <div className='flex justify-center items-center my-2'>
                    <h1 className='text-xl'>Verify Code</h1>
                </div>
                {
                    errMessage && (
                        <Error  className='my-2' message={errMessage} />
                    )
                }
                {
                    loading 
                    ?
                    (
                        <>
                            <Loading/>
                        </>
                    )
                    :(
                        <div className="flex space-x-2 items-center justify-center">
                            {inputs.map((input, index) => (
                                <input
                                    key={index}
                                    id={`input-${index}`}
                                    type="text"
                                    maxLength="1"
                                    value={input}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onPaste={handlePaste}
                                    ref={(ref) => inputRefs.current[index] = ref}
                                    className="w-12 h-12 text-center border rounded focus:outline-none focus:border-Mybg mb-[20px]"
                                />
                            ))}
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default VerfificationSignIn;