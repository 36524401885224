import axios from 'axios';
import React, { useState } from 'react'
import { squircle } from 'ldrs'
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const ModalUpdatePrice = ({ isOpen, onClose, onConfirm , packageName  , id  , refereshData}) => {
    const token_admin = useSelector((state) => state.admin.token_admin);

    const [newPrice , setNewPrice] = useState()
    const [currency , setCurrency] = useState()
    const getNewPrice = (e) => {
        setNewPrice(e.target.value)
    }   
    const getNewCurrency = (e) => {
        setCurrency(e.target.value)
    }   

    const [loading , setLoading] = useState(false)
    squircle.register()

    const handleCancel = () => {
        onClose();
    };

    const UpdatePrice = async () => {
        try { 
            setLoading(true);
            onConfirm();
            const formData = new FormData();
            formData.append('price', newPrice);
            formData.append('currency', currency);
    
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/packages/${id}/update-price/`, formData , {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Content-Type': 'application/json',
                    'Accept-Language' : localStorage.getItem('language')
                }
            });
    
            if (response.data) {
                onClose();
                refereshData()
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };    
  return (
    <motion.div 
    className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    initial={{ opacity: 0 }}
    animate={{ opacity: isOpen ? 1 : 0 }}
    transition={{ duration: 0.3 }}
    >
        <div className={`fixed  top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md w-full md:w-[600px]">
                <p className="text-lg font-medium mb-4 text-black">Update {packageName}</p>
                <div className='flex '>
                    <div className='flex flex-col flex-1 mx-1'>
                        <label>New Price</label>
                        <input type='text' className='py-2 px-2 rounded-md border-2 border-Mybg' value={newPrice} onChange={getNewPrice} />
                    </div>
                    <div className='flex flex-col w-1/6 md:w-1/5'>
                        <label>Currency</label>
                        <select className='py-2.5 px-2 rounded-md border-2 border-Mybg bg-white' value={currency} onChange={getNewCurrency}>
                            <option>EUR</option>
                            <option>USD</option>
                        </select>
                    </div>
                </div>
            <div className="flex justify-end mt-10">
                <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded"onClick={handleCancel}>Cancel</button>
                <button className="bg-yellow-500 hover:bg-yellow-400 text-white px-4 py-2 rounded"onClick={UpdatePrice}>
                    {loading ? <l-squircle size="18"stroke="4"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : 'Update'}
                </button>
            </div>
            </div>
        </div>
    </motion.div>
  )
}

export default ModalUpdatePrice