import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Package from '../../Assets/Icons/Package';
import { useTranslation } from 'react-i18next';
import Loading from '../../Assets/Loading/Loading';
import axios from 'axios';
import LoadingImg from '../../Assets/Pics/loading_img.png'
import Vide from '../../Assets/Pics/Vide.png'
const ServicesAuth = () => {
    const scrollFromLeftVariants = {
        hidden: { x: -2000 },
        visible: { x: 10, transition: { type: 'tween', duration: 0.8 } },
    };
    const navigate = useNavigate()

    //Tanslation
    const {t} = useTranslation('global')

    //Fetsh service
    const [service , setService] = useState([])
    const [loading , setLoading] = useState(false)

    const fetshService = async () =>{
        try{
            setLoading(true)
            const response = await axios.get(process.env.REACT_APP_SERVER + '/service/getEnableSerive');
            if(response.data){
                setService(response.data)
            }
        } catch(error) {
            console.error('Error fetching services:', error);
        } finally{
            setLoading(false)
        }   
    }

    useEffect(() => {
        fetshService();

        const intervalId = setInterval(() => {
          fetshService();
        }, 3 * 60 * 1000);
      
        return () => clearInterval(intervalId);
    }, []);
  return (
    <div className='container mx-auto md:px-20 my-20 md:my-[120px]'>
        <div className='mt-20'>
            <h1 className='lg:text-start text-center text-2xl'>{t("ServicesForAuthentified.title")}</h1>
            <p className='text-slate-500 text-center lg:text-start text-md mt-2'>{t("ServicesForAuthentified.description")}</p>
        </div>
        <div className='my-5 text-center lg:text-start'>
            <span className='font-md'>{t("ServicesForAuthentified.SortBy")} :</span> 
            <button className='py-1 px-2 border-slate-100 border-2 rounded-md mx-1 transition-all duration-500 hover:scale-105'>{t("ServicesForAuthentified.Recent")}</button>
            <button className='py-1 px-2 border-slate-100 border-2 rounded-md mx-1 transition-all duration-500 hover:scale-105'>{t("ServicesForAuthentified.Ancient")}</button>
        </div>
        <div className='flex flex-wrap mx-10 lg:mx-0 lg:justify-start justify-center lg:mb-10'>
            {  loading 
            ? 
                <>
                    <Loading />
                    <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                        <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                    </div>
                </>
            : 
                <>
                    {service ? service.map((serviceItem, index) => (
                        <motion.div key={serviceItem._id} className='flex-grow flex-shrink-0 w-full lg:w-1/4 my-1 cursor-pointer shadow-lg mx-1 md:my-1 bg-BgService rounded-md hover:bg-BgServiceHover transition-all duration-500' initial='hidden' animate='visible' variants={scrollFromLeftVariants} onClick={() => { navigate(`service/${serviceItem._id}`); }}>
                            <div className='text-center pt-10 md:pt-12 hover:scale-90 transition-all duration-500 '>
                                {
                                    serviceItem.ServicePicture
                                    ? (
                                        <>
                                            <img className='flex justify-center items-center mx-auto mt-5' src={`${process.env.REACT_APP_SERVER}/service_picture/${serviceItem.ServicePicture}`} />
                                            <h1 className='text-xl text-white'>{serviceItem.name}</h1>
                                        </>
                                    ) : (
                                        <>
                                            <h1 className='text-4xl mt-[20px] text-white'>{serviceItem.name}</h1>
                                        </>
                                    )
                                }
                                <div className='flex justify-end items-center mr-1 mt-[28px]'>
                                    <span className='text-white font-semibold'>{serviceItem.packageCount}&nbsp;</span>
                                    <Package />
                                </div>
                            </div>
                        </motion.div>
                    )) : (
                        <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                            <img src={Vide} alt='Vide' className='h-full w-full object-contain' />
                        </div>
                    )}
                </>
            }
        </div>
    </div>
  )
}

export default ServicesAuth