import React, { useState } from 'react';
import M3u from './M3u';
import Mac from './Mac';
import ActiveCode from './ActiveCode';
import { useSelector } from 'react-redux';

const DeviceType = () => {

  const m3uPassword = useSelector(state => state.checkout.m3u_password);
  const m3uUserName = useSelector(state => state.checkout.m3u_userName);
  const macMacAddress = useSelector(state => state.checkout.mac_mac_adress);
  const activeCodeRedux = useSelector(state => state.checkout.activeCode_ActiveCode);

  const [m3u, setMu3] = useState(m3uPassword && m3uUserName ? true : false);
  const [mac, setMac] = useState(macMacAddress ? true : false);
  const [activeCode, setActiveCode] = useState(activeCodeRedux ? true : false);

  const toggleMu3 = () => {
    setMu3(!m3u);
    setMac(false);
    setActiveCode(false);
  };

  const toggleMac = () => {
    setMu3(false);
    setActiveCode(false);
    setMac(!mac);
  };

  const toggleActiveCode = () => {
    setMac(false);
    setMu3(false);
    setActiveCode(!activeCode);
  };


  return (
    <div className='flex flex-col rounded-md bg-slate-100 py-20 justify-center items-center my-10 px-5'>
      <div className='flex'>
        <div className='w-1/3'>
          <button className={`${m3u ? 'bg-Mybg' : 'bg-MybgHover'} rounded-md py-2 px-5 md:px-10 text-white`} onClick={toggleMu3} disabled={macMacAddress || activeCodeRedux && 1 }>m3u</button>
        </div>
        <div className='w-1/3 md:mx-7 mx-1'>
          <button className={`${mac ? 'bg-Mybg' : 'bg-MybgHover'} rounded-md py-2 px-5 md:px-10 text-white`} onClick={toggleMac} disabled={m3uPassword && m3uUserName || activeCodeRedux && 1}>mac</button>
        </div>
        <div className='w-1/3'>
          <button className={`${activeCode ? 'bg-Mybg' : 'bg-MybgHover'} rounded-md py-2 px-3 md:px-5 text-white`} onClick={toggleActiveCode} disabled={m3uPassword && m3uUserName || macMacAddress && 1}>activeCode</button>
        </div>
      </div>
      {m3u && <M3u />}
      {mac && <Mac />}
      {activeCode && <ActiveCode />}
    </div>
  );
};

export default DeviceType;