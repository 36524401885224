import React from 'react';
import { Link } from 'react-router-dom';
import './Hero.css'
import { useTranslation } from 'react-i18next';

const Hero = () => {

  //Translation
  const { t } = useTranslation("global");

  return (
    <section className="Hero mb-[100px]">
    <div className="Hero_wrraper">
        <div className="Hero_intro">
          <h1 className='font-medium text-3xl md:text-4xl'>{t("mainPage.Hero.title")}</h1>
          <p className='font-light'>{t("mainPage.Hero.description")}</p>
          <Link to={'/signIn'} className='bg-Mybg shadow-lg hover:bg-MybgHover hover:scale-105 transition-all duration-500 text-myColor rounded-md py-4 px-20 font-medium'>
            {t("mainPage.Hero.button")}
          </Link>
        </div>
        <div className="Hero_ads md:mb-0 mb-[100px] md:mr-0 md:pl-10 md:mt-0 mt-[10px]">
          <div className=" bg-black Hero_box Hero_smallRectangle__FV427 Hero_spotify__ThmJR hidden shadow-lg">
            <img src="Pictures/Hero_MainPage/spotify.png" alt="Tod"/>
          </div>
          <div className="bg-black Hero_box Hero_bigRectangle__n6NW6 Hero_tod__i3WqF shadow-xl" style={{'width': '100px', 'height': '100px'}}>
            <img src="Pictures/Hero_MainPage/tod.png" alt="Tod"/>
          </div>
          <div className="Hero_box Hero_bigRectangle__n6NW6 bg-black shadow-xl">
            <img src="Pictures/Hero_MainPage/netflix.png" alt="Tod"/>
          </div>

          <div className="bg-black Hero_box  Hero_smallRectangle__FV427 shadow-xl" style={{'position': 'absolute', 'top': '70%', 'left': "20%", 'width': '200px',' height': '70px'}}>
            <img src="Pictures/Hero_MainPage/disney.png" alt="Tod"/>
          </div>
       </div>
       <div className="Hero_animation">
          <div className="Hero_animation_bg"></div>
        </div>
    </div>
   </section>
  );
}

export default Hero;