import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLatestPath } from '../../../Redux/Slices/CheckoutSlice';

const WarningNotification = ({ msg }) => {
    // From left to right
    const scrollFromLeftVariants = {
      hidden: { y: 100 },
      visible: { y: 0, transition: { type: 'tween', duration: 0.8 } },
    };

    const [open , setOpen] = useState(true)

    const navigate = useNavigate()
    const { t } = useTranslation('global');
    const { id } = useParams()
    const dispatch = useDispatch();
    const redirect = () => {
      if (id) {
        dispatch(setLatestPath(`/checkout/${id}`));
      }
      navigate('/signIn');
    }    
  return (
  <>
    {open && (
      <motion.div initial='hidden' animate='visible' variants={scrollFromLeftVariants}>
        <div className='flex justify-center md:mx-[100px]'>
          <div id="toast-default" className="flex fixed items-center w-full md:w-1/2 p-4 text-gray-500 bg-white rounded-md shadow-lg" role="alert">
            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-white bg-Mybg rounded-lg ">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ms-3 text-sm font-normal">{msg}</div>
            <button onClick={redirect} type="button" className="ms-auto -mx-1.5 -my-1.5 bg-Mybg text-white hover:bg-MybgHover rounded-md focus:ring-2 focus:ring-gray-300 py-1 px-2 inline-flex items-center justify-center" data-dismiss-target="#toast-success" aria-label="Close">
              {t('header.SignIn')}
            </button>
          </div>
        </div>
      </motion.div>
    )}
  </>

  )
}

export default WarningNotification