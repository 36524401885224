import axios from 'axios'
import React, { useState } from 'react'
import { squircle } from 'ldrs'
import Error from '../../Assets/Alert/Error'
import VerfificationSignIn from './VerfificationSignIn'

const Auth = () => {
  squircle.register()
  const [email , setEmail] = useState()
  const [password , setPassword] = useState()
  const [loading , setLoading] = useState(false)
  const [errMessage , setErrMessage] = useState()

  const getEmail = (e) => {
    setEmail(e.target.value)
  }
  const getPassword = (e) => {
    setPassword(e.target.value)
  }

  const SignInSuperAdmin = async () =>{
    try {
      setLoading(true)
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      const response = await axios.post(process.env.REACT_APP_SERVER + '/superAdmin/signIn' , formData , {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language' : localStorage.getItem('language')
        }}
      )
      if(response.data){
        openModal()
      }
    } catch (error){
      console.error('Err', error)
      setErrMessage(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  //Open Modal Détail Data
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <div className='flex justify-center items-center h-screen flex-col'>
      <VerfificationSignIn isOpen={isModalOpen} onClose={closeModal} email={email}/>
      <div className='text-center'>
        <h1 className="text-xl">Steam <span className="bg-black text-white rounded-md py-1 px-2">Sa</span></h1>
      </div>
      <div className="w-full px-10 lg:px-[450px]">
        {
          errMessage && (
            <Error  className='my-2' message={errMessage} />
          )
        }
        <div className="my-2 flex flex-col">
          <label>Email</label>
          <input type="email" value={email} onChange={getEmail}  className="py-2 px-2 rounded-md border-2 border-slate-300 shadow-lg focus:border-slate-300"  />
        </div>
        <div className="my-2 flex flex-col">
          <label>Password</label>
          <input type="password" value={password} onChange={getPassword} className="py-2 px-2 rounded-md border-2 border-slate-300 shadow-lg focus:border-slate-300"  />
        </div>
        <div className="my-2 flex flex-col">
          <button onClick={SignInSuperAdmin}  className="bg-black rounded-md py-2.5 text-white shadow-lg hover:bg-slate-900">
          {loading ? <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : 'Sign In'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Auth