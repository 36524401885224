import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const ConfirmDeleteAdmin = ({ isOpen, onClose, onConfirm , adminName  , id  , fetchData}) => {
    const token_superadmin = useSelector((state) => state.superAdmin.token_superadmin);
    const handleCancel = () => {
        onClose();
    };
    const deleteSubscription = async () => {
        try {
            onConfirm();
    
            const response = await axios.delete(process.env.REACT_APP_SERVER + `/admin/delete/${id}` , {
                headers: {
                    'Authorization': `Bearer ${token_superadmin}`,
                    'Content-Type': 'application/json',
                    'Accept-Language' : localStorage.getItem('language')
                }
            }) 
    
            if (response.data) {
                onClose();
                fetchData()
            }
        } catch (error) {
            console.log(error);
        }
    };
  return (
    <motion.div 
    className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    initial={{ opacity: 0 }}
    animate={{ opacity: isOpen ? 1 : 0 }}
    transition={{ duration: 0.3 }}
    >
        <div className={`fixed  top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px]">
                <p className="text-lg font-medium mb-4 text-black">Confirm</p>
                <p className='text-md text-slate-500'>
                    Are you sure you want to delete this <span className='text-Mybg font-medium'>{adminName}</span>? This action cannot be undone.
                </p>
            <div className="flex justify-end mt-10">
                <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded"onClick={handleCancel}>Cancel</button>
                <button className="bg-red-500 hover:bg-red-400 text-white px-4 py-2 rounded"onClick={deleteSubscription}>
                    Delete
                </button>
            </div>
            </div>
        </div>
    </motion.div>
  )
}

export default ConfirmDeleteAdmin