import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Color from '../../Assets/Icons/Color';
import axios from 'axios';
import Loading from '../../Assets/Loading/Loading';
import LoadingImg from "../../Assets/Pics/loading_img.png"
import { useSelector } from 'react-redux';

const Theme = () => {
    const [myBgColor, setMyBgColor] = useState('');
    const [myBgHoverColor, setMyBgHoverColor] = useState('');

    const [loading , setLoading] = useState(false)
    const token_admin = useSelector((state) => state.admin.token_admin);

    const scrollFromLeftVariants = {
        hidden: { x: -1000 },
        visible: { x: 10, transition: { type: 'tween', duration: 0.8 } },
    };

    const handleButtonClick = async (bg , hover) => {
        try{
            setLoading(true)
            const formData = new FormData();
            formData.append('Mybg', bg);
            formData.append('MybgHover', hover);
            const response = await axios.put(process.env.REACT_APP_SERVER + '/theme/edit-theme' , formData ,  { 
                headers: {
                  'Authorization': `Bearer ${token_admin}` , 
                  'Content-Type': 'application/json',
                  'Accept-Language' : localStorage.getItem('language')
                }
            })
            if(response.data){
                document.documentElement.style.setProperty('--tw-bg-Mybg', response.data.data.theme.Mybg);
                document.documentElement.style.setProperty('--tw-bg-MybgHover', response.data.data.theme.MybgHover);
            }
        } catch (error) {
            console.error('Error' , error)
            setMyBgColor('')
            setMyBgColor('')
        } finally {
            setLoading(false)
        }
    };
  return (
    <div className='container px-5 md:px-20 my-20 md:my-[325px] lg:my-[130px] mx-auto'>
        <div className='mt-20'>
            <h1 className='md:text-start text-center text-2xl'>Theme</h1>
            <p className='text-slate-500 text-center md:text-start text-md mt-2'>
                In this page, you can change the colors of the Steam complete
            </p>
        </div>
        {
            loading 
            ?
            <>
                <Loading />
                <div className='flex justify-center items-center'>
                    <div className='h-[300px] w-[300px]'>
                        <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                    </div>
                </div>
            </>
            : 
            <motion.div className='flex mx-auto container flex-wrap justify-start mt-5 md:pr-0 pr-5' initial='hidden' animate='visible' variants={scrollFromLeftVariants}>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#84cc16' , '#a3e635')}}>
                    <div className='bg-lime-500 py-5 px-5 rounded-md hover:bg-lime-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Lime</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#ef4444' , '#f87171')}}>
                    <div className='bg-red-500 py-5 px-5 rounded-md hover:bg-red-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Red</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#f97316' , '#fb923c')}}>
                    <div className='bg-orange-500 py-5 px-5 rounded-md hover:bg-orange-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Orange</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#eab308' , '#facc15')}}>
                    <div className='bg-yellow-500 py-5 px-5 rounded-md hover:bg-yellow-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Yellow</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#3b82f6' , '#60a5fa')}}>
                    <div className='bg-blue-500 py-5 px-5 rounded-md hover:bg-blue-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Blue</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#8b5cf6' , '#a78bfa')}}>
                    <div className='bg-violet-500 py-5 px-5 rounded-md hover:bg-violet-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Violet</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#a855f7' , '#c084fc')}}>
                    <div className='bg-purple-500 py-5 px-5 rounded-md hover:bg-purple-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Purpel</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#ec4899' , '#f472b6')}}>
                    <div className='bg-pink-500 py-5 px-5 rounded-md hover:bg-pink-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Pink</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#f43f5e' , '#fb7185')}}>
                    <div className='bg-rose-500 py-5 px-5 rounded-md hover:bg-rose-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Rose</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 md:px-0 px-1 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#020617' , '#0f172a')}}>
                    <div className='bg-slate-950 py-5 px-5 rounded-md hover:bg-slate-900 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Black</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 my-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg md:px-0 px-1' onClick={()=>{handleButtonClick('#22c55e' , '#4ade80')}}>
                    <div className='bg-green-500 py-5 px-5 rounded-md hover:bg-green-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Green</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
                <div className='lg:w-1/5 my-1 md:px-0 px-1 md:w-1/4 w-1/2 md:flex-shrink-0 cursor-pointer mb-4 md:mb-0 md:mr-4 shadow-lg' onClick={()=>{handleButtonClick('#2dd4bf' , '#14b8a6')}}>
                    <div className='bg-teal-500 py-5 px-5 rounded-md hover:bg-teal-400 flex items-center justify-center'>
                        <h1 className='text-white text-center text-xl'>Teal</h1>
                        <span className='flex justify-end text-white ml-1'><Color /></span>
                    </div>
                </div>
            </motion.div>
        }
    </div>
  )
}

export default Theme