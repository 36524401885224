import React from 'react'
import { motion } from 'framer-motion';

const DetailsUser = ({isOpen, onClose , fisrtName , lastName , country , phone , email , countSubscription , confirmed}) => {
    const handleCancel = () => {
        onClose();
    };
  return (
    <motion.div 
    className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    initial={{ opacity: 0 }}
    animate={{ opacity: isOpen ? 1 : 0 }}
    transition={{ duration: 0.3 }}
    >
        <div className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px] w-full">
                <div className='flex justify-center my-2 flex-col items-center'>
                    <h1 className='text-lg my-4 '>{fisrtName}&nbsp;{lastName}</h1>
                    <li>Country : {country}</li>
                    <li>Phone : {phone}</li>
                    <li>Email : {email}</li>
                    <li>Confirmed : {confirmed == true ? 'Confirmed' : 'Not Confirmed'}</li>
                    <li>Subscription : {countSubscription}</li>
                </div>
                <div className="flex justify-end mt-5">
                    <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </motion.div>
  )
}

export default DetailsUser