import React from 'react'
import { motion } from 'framer-motion';

const DetailsSubscription = ({ isOpen, onClose, name, paymentStatus, Duration, SubDate, deviceType, price, deviceDetails, paymentDate , paymentId }) => {
    const handleCancel = () => {
        onClose();
    };

    return (
        <motion.div 
        className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        >
            <div className={`fixed  top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
                <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md w-full md:w-[600px]">
                    <div className='flex justify-center items-center flex-col my-5'>
                        <h1 className='text-xl'>{name}</h1>
                        <h1 className='text-sm inline-flex items-center capitalize my-2'>
                            <span className='mx-1'>{paymentStatus === 'pending' ? <div className='w-2 h-2 bg-yellow-500 rounded-full p-2'></div> : paymentStatus === 'success' ? <div className='w-2 h-2 bg-green-500 rounded-full p-2'></div> : <div className='w-2 h-2 bg-red-500 rounded-full p-2'></div>}</span>
                            {paymentStatus}
                        </h1>
                    </div>
                    <div className='flex justify-center items-center flex-col'>
                        <h1 className='capitalize'>Subscription Date :&nbsp;<span className='text-Mybg'>{SubDate}</span></h1>
                        <h1 className='capitalize'>Expiration Date :&nbsp;<span className='text-Mybg'>{Duration}</span></h1>
                        <h1 className='capitalize'>Price :&nbsp;<span className='text-Mybg'>{price}</span></h1>
                        <h1 className='capitalize'>Payment Date :&nbsp;<span className='text-Mybg'>{paymentDate}</span></h1>
                        <h1 className='capitalize'>Payment Id :&nbsp;<span className='text-Mybg'>{paymentId}</span></h1>
                        <div className='flex items-center flex-col'>
                            <div>
                                <h1>Device Type : <span className='text-Mybg'>{deviceType}</span></h1>
                            </div>
                            {deviceType === "activeCode" ? (
                                <div className='bg-Mybg text-white py-2 rounded-md px-6 text-center my-2 w-full' key={deviceDetails?.code}>
                                    {deviceDetails?.code}
                                </div>
                            ) : deviceType === "mac" ? (
                                <div className='bg-Mybg text-white py-2 rounded-md px-6 text-center my-2 w-full' key={deviceDetails?.mac}>
                                    {deviceDetails?.mac}
                                </div>
                            ) : (
                                <div key={deviceDetails?.password} className='mt-2 flex items-center'>
                                    <h1 className='my-2 flex flex-col mx-1 justify-center items-center'>UserName : <span className='text-white bg-Mybg rounded-md py-1.5 px-1'>{deviceDetails?.userName}</span></h1>
                                    <h1 className='my-2 flex flex-col mx-1 justify-center items-center'>Password : <span className='text-white bg-Mybg rounded-md py-1.5 px-1'>{deviceDetails?.password}</span></h1>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end mt-10">
                        <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleCancel}>Back</button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default DetailsSubscription