import React, { useState } from 'react'
import Profile from '../../Assets/Icons/Profile'
import Hide from '../../Assets/Icons/Hide'
import Show from '../../Assets/Icons/Show'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { setToken } from '../../Redux/Slices/AuthSlice'
import { useDispatch } from 'react-redux'
import Success from '../../Assets/Alert/Success'
import Error from '../../Assets/Alert/Error'
import { squircle } from 'ldrs'

const ProfileUser = () => {
  squircle.register()
  const [showNewPassword , setShowNewPassword] = useState(false)
  const [showConfirmPassword , setShowConfirmPassword] = useState(false)
  const toggleNewPasswordVisibilty = () =>{
    setShowNewPassword(!showNewPassword)
  }
  const toggleConfirmPasswordVisibilty = () =>{
    setShowConfirmPassword(!showConfirmPassword)
  }

  const [FisrtName , setFirstName] = useState(Cookies.get('FirstName') || '')
  const [LastName , setLastName] = useState(Cookies.get('LastName') || '')
  const [email , setEmail] = useState(Cookies.get('email') || '')
  const [OldPassword , setOldPassword] = useState('')
  const [NewPassword , setNewPassword] = useState('')
  const [ConfirmPassword , setConfirmPassword] = useState('')
  const [loading , setLoading] =useState(false)
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [successMessage , setSuccessMessage] = useState()
  const [errMessage , setErrMessage] = useState()

  //Change Credentials
  const changeProfile = async () =>{
    try{
    setLoading(true)
    const formData = new FormData();

    formData.append('newFirstName', FisrtName);
    formData.append('newLastName', LastName);
    formData.append('email', email);
    formData.append('password', OldPassword);
    formData.append('newPassword', NewPassword);
    formData.append('confirmPassword', ConfirmPassword);
    const response = await axios.put(process.env.REACT_APP_SERVER + '/auth/profile' , formData  , {
      headers: {
        'Content-Type': 'application/json',
        'secret_key' : process.env.REACT_APP_KEY_BACKEND,
        'Authorization' : `Bearer ${token}`,
        'Accept-Language' : localStorage.getItem('language')
      },
    })

    if(response.data){
      setSuccessMessage(response.data.message)
      Cookies.set('email' , response.data.user.email)
      Cookies.set('FirstName' , response.data.user.FirstName)
      Cookies.set('LastName' , response.data.user.LastName)
      dispatch(setToken(response.data.token));
    }

  } catch (error) {
      console.error(error)
      setErrMessage(error.response.data.error);
  } finally{
    setLoading(false)
  }
  }

  //Translation
  const {t} = useTranslation('global')

  //get Values
  const getFirstName = (e) => {
    setFirstName(e.target.value)
  }
  const getLastName = (e) => {
    setLastName(e.target.value)
  }
  const getEmail = (e) => {
    setEmail(e.target.value)
  }
  const getOldPassword = (e) => {
    setOldPassword(e.target.value)
  }
  const getNewPassword = (e) => {
    setNewPassword(e.target.value)
  }
  const getConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
  }

  return (
    <div className='flex items-center my-[120px] md:h-screen justify-center flex-col'>
        {/* Image Profile */}
        <div className='mx-auto flex justify-center items-center'>
            <span className='text-white hover:rotate-12 transition-all duration-500'><Profile/></span>
        </div>
        {
          successMessage && <div className='w-[300px] lg:w-[500px]'><Success message={successMessage} /></div>
        }
        {
          errMessage && <div className='w-[300px] lg:w-[500px]'><Error message={errMessage} /></div>
        }
        <div className='flex flex-col items-center'>
          <div className='flex flex-col mt-5'>
            <label>{t("Profile.FirstName")}</label>
            <input type="text" className='py-1.5 px-4 w-[300px] lg:w-[500px] rounded-md border-2 border-Mybg' placeholder='John Doe' value={FisrtName} onChange={getFirstName} />
          </div>
          <div className='flex flex-col mt-1'>
            <label>{t("Profile.LastName")}</label>
            <input type="text" className='py-1.5 px-4 w-[300px] lg:w-[500px]  rounded-md border-2 border-Mybg' placeholder='John Doe' value={LastName} onChange={getLastName} />
          </div>
          <div className='flex flex-col my-1'>
            <label>Email</label>
            <input type="email" className='py-1.5 px-4 w-[300px] lg:w-[500px] rounded-md border-2 border-Mybg' placeholder='name.gmail.com' value={email} onChange={getEmail} />
          </div>
          <div className='flex relative flex-col my-1'>
            <label>{t("Profile.AncientPassword")}</label>
            <input type='password'  className='py-1.5 px-4 w-[300px] lg:w-[500px] rounded-md border-2 border-Mybg' placeholder='*********' value={OldPassword} onChange={getOldPassword}  />
          </div>
          <div className='flex relative flex-col my-1'>
            <label>{t("Profile.NewPassword")}</label>
            <input type={showNewPassword ? 'text' : 'password'}  className='py-1.5 px-4 w-[300px] lg:w-[500px] rounded-md border-2 border-Mybg' placeholder='*********' value={NewPassword} onChange={getNewPassword}  />
            <span className="absolute top-[28px] right-2 cursor-pointer" onClick={toggleNewPasswordVisibilty}>
                {showNewPassword ? <Hide /> : <Show />}
            </span>
          </div>
          <div className='flex relative flex-col my-1'>
            <label>{t("Profile.ConfirmPassword")}</label>
            <input type={showConfirmPassword ? 'text' : 'password'}  className='py-1.5 px-4 w-[300px] lg:w-[500px] rounded-md border-2 border-Mybg' placeholder='*********' value={ConfirmPassword} onChange={getConfirmPassword}  />
            <span className="absolute top-[28px] right-2 cursor-pointer" onClick={toggleConfirmPasswordVisibilty}>
                {showConfirmPassword ? <Hide /> : <Show />}
            </span>
          </div>
          <div className='mt-2'>
              <button onClick={changeProfile}  className='rounded-md bg-Mybg hover:bg-MybgHover text-white w-[300px] lg:w-[500px] py-2'>
                {loading ? <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : t("Profile.button")}
              </button>
          </div>
        </div>
    </div>
  )
}

export default ProfileUser