import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Hide from '../../Assets/Icons/Hide'
import Show from '../../Assets/Icons/Show'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import Background from '../../Assets/Pics/Background'
import { squircle } from 'ldrs'
import axios from 'axios'
import Warning from '../../Assets/Alert/Warning'
import Success from '../../Assets/Alert/Success'
import Error from '../../Assets/Alert/Error'
import { useDispatch, useSelector } from 'react-redux';
import { setReseller, setToken } from '../../Redux/Slices/AuthSlice'

const SignIn = () => {
  squircle.register()
  const [showPassword , setShowPassword] = useState(false)
  const togglePasswordVisibilty = () =>{
    setShowPassword(!showPassword)
  }

  const dispatch = useDispatch();
  const latestPath = useSelector(state => state.checkout.latestPath);
  const [email , setEmail] = useState()
  const [password , setPassword] = useState()
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading ,setLoading] = useState(false)
  const [successMessage , setSuccessMessage] = useState()
  const [errMessage , setErrMessage] = useState()
  const [tarker,setTarker] = useState(0);
  // Translation
  const { t } = useTranslation("global");

  const navigate = useNavigate()

  const handleSignIn = async () =>{

    setTarker(tarker + 1);
    if (tarker < 4)
    {
      try{
        setLoading(true)
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        const response = await axios.post(process.env.REACT_APP_SERVER + '/auth/signin' , formData , {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language' : localStorage.getItem('language')
          }
        })
        if(response.data){
          dispatch(setToken(response.data.accessToken));
          dispatch(setReseller(response.data.reseller));
          Cookies.set('token' , response.data.accessToken , { expires: 10 })
          Cookies.set('id' , response.data.user.id , { expires: 10 })
          Cookies.set('email' , response.data.user.email , { expires: 10 })
          Cookies.set('FirstName' , response.data.user.FirstName , { expires: 10 })
          Cookies.set('LastName' , response.data.user.LastName , { expires: 10 })
          Cookies.set('Reseller' , response.data.user.reseller , { expires: 10 })
          setSuccessMessage(response.data.message)
        }
        if(latestPath){
          navigate(latestPath);
        }else{
          navigate('/mySubscribtion')
        }
      } catch (error){
        setErrMessage(error.response.data.error)
      } finally{
        setLoading(false)
      }
    }
    else
    {
      console.log("sss")
    }
  }

  //Get Email Value
  const onChangeEmail = (e) =>{
    setEmail(e.target.value)
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError(t("SignIn.EmailErr"));
    } else {
      setEmailError('');
    }
  }

  //Get Password Value
  const onChangePassword = (p) => {
    setPassword(p.target.value)
    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*()_+}{|":?><,./;'[\]\\`~=-]{8,}$/.test(password)) {
      setPasswordError(t("SignIn.PasswordErr"));
    } else {
      setPasswordError('');
    }
  }

  const errorMessages = [ emailError , passwordError ];

  return (
    <>
      {
        tarker === 4 ?<div class="flex justify-center items-center h-screen">
          <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong class="font-bold">Holy smokes!</strong>
            <span class="block xl:inline">you are blocked !!.</span>
            <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>
          </div>
        </div>
        :
        <div className="flex h-screen md:items-center">
      <div className='lg:w-1/2 my-20 md:my-36 w-full mx-auto md:px-[100px] lg:px-[40px] px-5'>
        <div className='text-center'>
            <Link className='text-3xl ' to={'/'}>{process.env.REACT_APP_NAME}</Link>
        </div>
        <div className='text-start mt-10'>
            <h1 className='text-xl text-black'>{t("SignIn.title")}</h1>
            <p className='text-slate-500 text-lg'>{t("SignIn.description")}</p>
        </div>
        {/* Warning Alert */}
        {
          (emailError || passwordError) && (
            <ul className='list-disc list-inside'>
              {errorMessages && <Warning  messages={errorMessages} />}
            </ul>
          )
        }
        {/* Success message */}
        {
          successMessage && (
            <Success message={successMessage} />
          )
        }
        {/* Err message */}
        {
          errMessage && (
            <Error  className='my-2' message={errMessage} />
          )
        }
        <div className='flex justify-start flex-col mt-12'>
            <label>Email</label>
            <input type="text" className='py-1.5 px-4 border-2 border-MybgHover rounded-md' placeholder='name@gamil.com' value={email} onChange={onChangeEmail}/>
        </div>
        <div className='flex relative justify-start flex-col my-2'>
            <label>{t("SignIn.password")}</label>
            <input type={showPassword ? 'text' : 'password'} className='py-1.5 px-4 border-2 border-MybgHover rounded-md' placeholder='********' value={password} onChange={onChangePassword} />
              <span className="absolute top-[28px] right-2 cursor-pointer" onClick={togglePasswordVisibilty}>
                {showPassword ? <Hide /> : <Show />}
            </span>
        </div>
        <div className='flex justify-start flex-col my-2'>
            <button onClick={handleSignIn} disabled={!email || !password ? 1 : 0 } className={` py-3 transition-all duration-500 rounded-md text-myColor ${!email || !password ? 'bg-MybgHover' : 'bg-Mybg hover:bg-MybgHover' }`}>
            {loading ? <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : t('SignIn.title')}
            </button>
        </div>
        <div className='flex justify-start flex-col my-2'>
            <p className='text-slate-500'>{t("SignIn.question")}&nbsp;<Link to={'/signUp'} className='text-black'>{t("SignIn.SignUpLink")}</Link></p>
        </div>
      </div>
      <div className='lg:w-1/2 hidden lg:block'>
        <Background>
          <h1 className='text-white text-3xl flex justify-center items-center h-screen'>
            {t("SignIn.description")}
          </h1>
        </Background>
      </div>
    </div>
      }
    </>

  )
}

export default SignIn
