import React from 'react'
import Hero from './Hero/Hero'
import Service from './Services/Service'
import Featured from './Featureds/Featured'
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer'

const MainPage = () => {
  
  //Translation
  const { t } = useTranslation("global");

  return (
    <div>
      <div className='container mx-auto mt-10 md:mt-0 lg:mt-10'>
        <div>
            <Hero/>
        </div>
        <div className='mb-5'>
          <h1 className='font-medium text-3xl text-center mb-5 md:mt-0 mt-5'>Services</h1>
            <Service/>
        </div>
        <div className='mb-5'>
          <h1 className='font-medium text-3xl text-center mb-5 md:mt-0 mt-5'>{t("mainPage.Featured.title")}</h1>
            <Featured/>
        </div>
      </div>
        <div>
          <Footer/>
        </div>
    </div>
  )
}

export default MainPage