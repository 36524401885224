import React, { useEffect, useState } from 'react';
import Paypal from './Paypal_m/Paypal';
import Stripe from './Stripe/Stripe';
import CryptoPayment from './CryptoPayment/CryptoPayment'; 
import GooglePay from './GooglePay/GooglePay'; 
import PaypalIcon from '../../../Assets/Pics/PaypalIcon.png';
import CryptoIcon from '../../../Assets/Pics/CryptoIcon.png';
import StripeIcon from '../../../Assets/Pics/StripeIcon.png';
import GooglePayIcon from '../../../Assets/Pics/GooglePayIcon.png';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Error from '../../../Assets/Alert/Error';

const PaymentMethod = () => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [error , setError] = useState()
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const payParam = urlParams.get('pay');

  const handlePaymentMethodSelect = (method) => {
    setSelectedMethod(method);
  };

  //Translation
  const {t} = useTranslation('global')

  useEffect(() => {
    if (payParam === 'false') {
      setError("Le paiement a été annulé.");
    }
  }, [payParam]);

  return (
    <div className='bg-slate-100 rounded-md px-5 py-5 my-5'>
      <div className='text-center my-5'>
        <h1 className='text-center text-xl'>{t("Checkout.PayementMethods.title")}</h1>
      </div>
      {
        error && (
          <div className='flex w-full md:w-1/3 justify-center items-center mx-auto'>
            <Error message={error} />
          </div>
        )
      }
      <div className='md:text-start text-center'>
        <h1 className='text-slate-800 my-4'>{t("Checkout.PayementMethods.description")}</h1>
      </div>
      <div>
        <div className='flex md:justify-start justify-center items-center'>
            <button
              className={`hover:scale-105 transition-all inline-flex items-center mx-2 border-2 border-slate-300 rounded-md ${
                selectedMethod === 'PayPal' ? 'bg-slate-200' : ''
              }`}
              onClick={() => handlePaymentMethodSelect('PayPal')}
            >
              <img src={PaypalIcon} alt="Paypal" className='w-[60px] p-2' />
              <span className='hidden lg:block mx-10'>Paypal</span>
            </button>
            <button
              className={`hover:scale-105 transition-all inline-flex items-center mx-2 border-2 border-slate-300 rounded-md ${
                selectedMethod === 'Crypto' ? 'bg-slate-200' : ''
              }`}
              onClick={() => handlePaymentMethodSelect('Crypto')}
            >
              <img src={CryptoIcon} alt="Cryptocurrency" className='w-[60px] p-2'/>
              <span className='hidden lg:block mx-10'>Crypto</span>
            </button>
            <button
              className={`hover:scale-105 transition-all inline-flex items-center mx-2 border-2 border-slate-300 rounded-md ${
                selectedMethod === 'Stripe' ? 'bg-slate-200' : ''
              }`}
              onClick={() => handlePaymentMethodSelect('Stripe')}
            >
              <img src={StripeIcon} alt="Stripe" className='w-[60px] p-2'/>
              <span className='hidden lg:block mx-10'>Stripe</span>
            </button>
            <button
              className={`hover:scale-105 transition-all inline-flex items-center mx-2 py-2 md:py-[13px] border-2 border-slate-300 rounded-md ${
                selectedMethod === 'GooglePay' ? 'bg-slate-200' : ''
              }`}
              onClick={() => handlePaymentMethodSelect('GooglePay')}
            >
              <img src={GooglePayIcon} alt="Google Pay" className='w-[60px] p-2'/>
              <span className='hidden lg:block mx-10'>Google Pay</span>
            </button>
        </div>
      </div>

      {/* Display the selected payment component */}
      <div className='my-10 flex justify-center items-center'>
        {selectedMethod === 'PayPal' && <Paypal />}
        {selectedMethod === 'Crypto' && <CryptoPayment />}
        {selectedMethod === 'Stripe' && <Stripe />}
        {selectedMethod === 'GooglePay' && <GooglePay />}
      </div>
    </div>
  );
};

export default PaymentMethod;