import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Package from '../../Assets/Icons/Package';
import axios from 'axios';
import Loading from '../../Assets/Loading/Loading';
import LoadingImg from '../../Assets/Pics/loading_img.png'
import { useSelector } from 'react-redux';

const Services = () => {
    const [service , setService] = useState([])
    const [loading , setLoading] = useState(false)
const fetshService = async() => {
        try {
            setLoading(true)
            const response = await axios.get(process.env.REACT_APP_SERVER + '/service/all')
            if(response.data){
                setService(response.data)
            }
        } catch (error) {
            console.error('Error fetching services:', error);

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetshService();

        const intervalId = setInterval(() => {
          fetshService();
        }, 3 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    const token_admin = useSelector((state) => state.admin.token_admin);

    const enableSerice = async (id) => {
        try {
            const response = await axios.put(process.env.REACT_APP_SERVER + `/service/enable/${id}`, {} , {
                headers: {
                    'Authorization' : `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            })
            if(response.data) {
                fetshService();
            }
        } catch (error) {
            console.error(error)
        }
    }

    const disableSerice = async (id) => {
        try {
            const response = await axios.put(process.env.REACT_APP_SERVER + `/service/disable/${id}`, {} , {
                headers: {
                    'Authorization' : `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            })

            if(response.data) {
                fetshService();
            }
        } catch (error) {
            console.error(error)
        }
    }

    const navigate = useNavigate()
  return (
    <div className='flex flex-col mx-2 lg:mx-20'>
        <div className='flex justify-center md:justify-start items-center text-start mt-10'>
            <h1 className='text-2xl text-start'>Services</h1>
        </div>
        <div className='flex flex-wrap mx-10 lg:mx-0 lg:justify-start justify-center lg:mb-10 mt-5'>
        {
        loading
            ?
                <>
                    <Loading />
                    <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                        <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                    </div>
                </>
            :
                <>
                    {service ? service.map((serviceItem, index) => (
                        <>
                        <div key={serviceItem._id} className='flex-grow flex-shrink-0 w-full lg:w-1/4 my-1 cursor-pointer shadow-lg mx-1 md:my-1 bg-BgService rounded-md hover:bg-BgServiceHover transition-all duration-500'  >
                            <div className='text-center pt-10 md:pt-12 hover:scale-90 transition-all duration-500 ' onClick={() => { navigate(`/admin/packages/${serviceItem._id}`); }}>
                                {
                                    serviceItem.ServicePicture
                                    ? (
                                        <>
                                            <img className='flex justify-center items-center mx-auto mt-5' src={`${process.env.REACT_APP_SERVER}/service_picture/${serviceItem.ServicePicture}`} />
                                            <h1 className='text-xl text-white'>{serviceItem.name}</h1>
                                        </>
                                    ) : (
                                        <>
                                            <h1 className='text-4xl mt-[20px] text-white'>{serviceItem.name}</h1>
                                        </>
                                    )
                                }
                                <h1>{serviceItem.active ? <span className='text-green-500'>active</span> : <span className='text-red-500'>is not activated</span>}</h1>
                                <div className='flex justify-end items-center mr-1 mt-[15px]'>
                                    <span className='text-white font-semibold'>{serviceItem.packageCount}&nbsp;</span>
                                    <Package />
                                </div>
                            </div>
                            <div className='flex justify-end'>
                            <button disabled={serviceItem.active == true ? true : false} onClick={()=>{enableSerice(serviceItem._id)}} className={`text-white rounded-md py-2 px-2 mx-1 my-1 ${serviceItem.active == true ? 'bg-green-300' : 'bg-green-500 hover:bg-green-400'}`}>Enable</button>
                            <button disabled={serviceItem.active == false ? true : false} onClick={()=>{disableSerice(serviceItem._id)}} className={`text-white rounded-md py-2 px-2 mx-1 my-1 ${serviceItem.active == false ? 'bg-red-300' : 'bg-red-500 hover:bg-red-400'}`}>Disable</button>
                            </div>
                        </div>

                        </>
                    )) : <img src={LoadingImg} />}
                </>
            }
        </div>
    </div>
  )
}

export default Services
