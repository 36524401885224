import React, { useEffect, useState } from 'react';
import Package from '../../../Assets/Icons/Package';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../../Assets/Loading/Loading';
import LoadingImg from '../../../Assets/Pics/loading_img.png'

const Service = () => {
    const [service , setService] = useState([])
    const [loading , setLoading] = useState(false)

    const fetshService = async () =>{
        try{
            setLoading(true)
            const response = await axios.get(process.env.REACT_APP_SERVER + '/service/getEnableSerive');
            if(response.data){
                setService(response.data)
            }
        } catch(error) {
            console.error('Error fetching services:', error);
        } finally{
            setLoading(false)
        }   
    }

    useEffect(() => {
        fetshService();

        const intervalId = setInterval(() => {
            fetshService();
        }, 3 * 60 * 1000);
        
        return () => clearInterval(intervalId);
    }, []);

    const scrollFromLeftVariants = {
        hidden: { x: -3000 },
        visible: { x: 0, transition: { type: 'tween', duration: 0.8 } },
    };

    const navigate = useNavigate()

  return (
    <div className='container mx-auto'>
      <div className='flex flex-wrap lg:mx-0 lg:justify-start justify-center px-10'>
      {  loading 
            ? 
                <>
                    <Loading />
                    <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                        <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                    </div>
                </>
            : 
                <>
                    {service ? service.map((serviceItem, index) => (
                        <motion.div key={serviceItem._id} className='flex-grow flex-shrink-0 w-full lg:w-1/4 my-1 cursor-pointer shadow-lg mx-1 md:my-1 bg-BgService rounded-md hover:bg-BgServiceHover transition-all duration-500' initial='hidden' animate='visible' variants={scrollFromLeftVariants} onClick={() => { navigate(`service/${serviceItem._id}`); }}>
                            <div className='text-center pt-10 md:pt-12 hover:scale-90 transition-all duration-500 '>
                                {
                                    serviceItem.ServicePicture
                                    ? (
                                        <>
                                            <img className='flex justify-center items-center mx-auto mt-5' src={`${process.env.REACT_APP_SERVER}/service_picture/${serviceItem.ServicePicture}`} />
                                            <h1 className='text-xl text-white'>{serviceItem.name}</h1>
                                        </>
                                    ) : (
                                        <>
                                            <h1 className='text-4xl mt-[20px] text-white'>{serviceItem.name}</h1>
                                        </>
                                    )
                                }
                                <div className='flex justify-end items-center mr-1 mt-[28px]'>
                                    <span className='text-white font-semibold'>{serviceItem.packageCount}&nbsp;</span>
                                    <Package />
                                </div>
                            </div>
                        </motion.div>
                    )) : <img src={LoadingImg} />}
                </>
        }
      </div>
    </div>
  );
};

export default Service;