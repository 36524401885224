import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Error from '../../Assets/Alert/Error';
import axios from 'axios';
import { motion } from 'framer-motion';

const UpdateAdmin = ({ isOpen , onClose , adminId , AdminName , refereshData}) => {
    const handleCancel = () => {
        onClose();
    };
    const [adminName , setAdminName] = useState('')
    const [email , setEmail] = useState('')
    const [password , setPassword] = useState('')
    const token_superadmin = useSelector((state) => state.superAdmin.token_superadmin);
    const [errMsg, setErrorMsg] = useState('')

    const updateAdmin = async () => {
        try {
            const requestData = {
                adminName: adminName,
                email: email,
                password: password,
            };
    
            const response = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/update-admin/${adminId}`,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token_superadmin}`,
                        'Accept-Language': localStorage.getItem('language'),
                    },
                }
            );
    
            if (response.data) {
                refereshData();
                onClose();
            }
        } catch (error) {
            setErrorMsg(error.response.data.error);
        }
    };
  return (
    <motion.div 
    className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    initial={{ opacity: 0 }}
    animate={{ opacity: isOpen ? 1 : 0 }}
    transition={{ duration: 0.3 }}
    >
        <div className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px] w-full">
                <div className="text-center my-2">
                    <h1 className='text-xl'>Update {AdminName}</h1>
                </div>
                {
                    errMsg && (
                        <Error  className='my-2' message={errMsg} />
                    )
                }
                <div>
                    <div className='my-1 flex flex-col'>
                        <label>Admin Name</label>
                        <input type='text' className='rounded-md border-2 border-Mybg py-[4px] px-1' value={adminName} onChange={(f)=>{setAdminName(f.target.value)}}/>
                    </div>
                    <div className='my-1 flex flex-col'>
                        <label>Email</label>
                        <input type='email' className='rounded-md border-2 border-Mybg py-[4px] px-1' value={email} onChange={(f)=>{setEmail(f.target.value)}}/>
                    </div>
                    <div className='my-1 flex flex-col'>
                        <label>Password</label>
                        <input type='password' className='rounded-md border-2 border-Mybg py-[4px] px-1' value={password} onChange={(f)=>{setPassword(f.target.value)}}/>
                    </div>
                </div>
                <div className="flex justify-end mt-5">
                    <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleCancel}>Cancel</button>
                    <button className="bg-Mybg hover:bg-MybgHover px-4 py-2 mr-2 rounded text-white" onClick={updateAdmin}>Submit</button>
                </div>
            </div>
        </div>
    </motion.div>
  )
}

export default UpdateAdmin