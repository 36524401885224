import React, { useEffect, useState } from 'react';
import Top from '../../../Assets/Icons/Top';
import Bottom from '../../../Assets/Icons/Bottom';
import MaxTop from '../../../Assets/Icons/MaxTop';
import MaxBottom from '../../../Assets/Icons/MaxBottom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLiveBouquetData, setSerieBouquetData, setVodData, toggleLiveBouquet, toggleSeriesBouquet, toggleVodBouquet } from '../../../Redux/Slices/CheckoutSlice';
import axios from 'axios';
import Loading from '../../../Assets/Loading/Loading';
import { squircle } from 'ldrs'
import { useSelector } from 'react-redux';
import { setActiveStep } from '../../../Redux/Slices/CheckoutSlice';

const LiveBouquet = () => {
  squircle.register()
  const [initialBouquets, setInitialBouquets] = useState([]);
  const [initialVod, setInitialVod] = useState([]);
  const [initialSeries, setInitialSeries] = useState([]);
  const [loading , setLoading] = useState(false)
  const activeStep = useSelector(state => state.checkout.activeStep);
  const liveBouquetData = useSelector(state => state.checkout.liveBouquetData);

  const [bouquetData, setBouquetData] = useState({
    live: initialBouquets,
    vod: initialVod,
    series: initialSeries,
  });

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedSection, setSelectedSection] = useState('live');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(process.env.REACT_APP_SERVER + '/channel/activeChannels');
        const data = response.data[0];

        setInitialBouquets(data.LiveBouquet || []);
        setInitialVod(data.Vod || []);
        setInitialSeries(data.SerieBouquet || []);

        setBouquetData({
          live: data.LiveBouquet || [],
          vod: data.Vod || [],
          series: data.SerieBouquet || [],
        });
      } catch (error) {
        console.error('Error fetching channels:', error);
      }finally{
        setLoading(false)
      }
    };

    fetchData();
  }, []);

  const sortMaxTop = () => {
    const selectedBouquets = getSelectedBouquets();
    const unselectedBouquets = getUnselectedBouquets();
    const sortedBouquets = [...selectedBouquets, ...unselectedBouquets];
    updateBouquets(selectedSection, sortedBouquets);
  }

  const sortTop = () => {
    const selectedBouquets = getSelectedBouquets();
    const bouquetsForSection = getBouquetsForSection();
  
    const updatedBouquets = [...bouquetsForSection];
  
    for (let i = 0; i < updatedBouquets.length; i++) {
      const bouquet = updatedBouquets[i];
  
      if (selectedBouquets.includes(bouquet)) {
        const selectedIndex = selectedBouquets.indexOf(bouquet);
  
        if (i - selectedIndex > 0) {
          // Move the selected bouquet one position up
          updatedBouquets.splice(i, 1);
          updatedBouquets.splice(i - 1, 0, bouquet);
        }
      }
    }
  
    updateBouquets(selectedSection, updatedBouquets);
  };    

  const sortMaxBottom = () => {
    const selectedBouquets = getSelectedBouquets();
    const unselectedBouquets = getUnselectedBouquets();
    const sortedBouquets = [...unselectedBouquets, ...selectedBouquets];
    updateBouquets(selectedSection, sortedBouquets);
  }

  const sortBottom = () => {
    const selectedBouquets = getSelectedBouquets();
    const bouquetsForSection = getBouquetsForSection();
  
    const updatedBouquets = [...bouquetsForSection];
  
    for (let i = updatedBouquets.length; i >= 0; i--) {
      const bouquet = updatedBouquets[i];
  
      if (selectedBouquets.includes(bouquet)) {
        const selectedIndex = selectedBouquets.indexOf(bouquet);
  
        if (i + selectedIndex < updatedBouquets.length) {
          // Move the selected bouquet one position down
          updatedBouquets.splice(i, 1);
          updatedBouquets.splice(i + 1, 0, bouquet);
        }
      }
    }
  
    updateBouquets(selectedSection, updatedBouquets);
  };    

  const selectAll = () => {
    const allIds = [];
    for (const section in bouquetData) {
      const sectionBouquets = bouquetData[section];
      const sectionIds = sectionBouquets.map((bouquet) => bouquet._id);
      allIds.push(...sectionIds);
    }

    setSelectedIds(allIds);
  };

  const unselectAll = () => {
    setSelectedIds([]);
  };

  const toggleSelection = (bouquetId) => {
    if (selectedIds.includes(bouquetId)) {
      setSelectedIds(selectedIds.filter((_id) => _id !== bouquetId));
    } else {
      setSelectedIds([...selectedIds, bouquetId]);
    }
  };

  const getSelectedBouquets = () => {
    const sectionBouquets = getBouquetsForSection();
    return sectionBouquets.filter((bouquet) => selectedIds.includes(bouquet._id));
  };

  const getUnselectedBouquets = () => {
    const sectionBouquets = getBouquetsForSection();
    return sectionBouquets.filter((bouquet) => !selectedIds.includes(bouquet._id));
  };

  const getBouquetsForSection = () => {
    return bouquetData[selectedSection] || [];
  };

  const updateBouquets = (section, bouquets) => {
    setBouquetData({
      ...bouquetData,
      [section]: bouquets,
    });
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };

  const dispatch = useDispatch();

  const getSelectedBouquetsForSection = (section) => {
    const sectionBouquets = bouquetData[section];
  
    return sectionBouquets.map((bouquet) => ({
      _id: bouquet._id,
      name: bouquet.name,
      selected: selectedIds.includes(bouquet._id),
    }));
  };  
  
  const [loadingSubmission , setLoadingSubmission] = useState(false)

  const handleSubmission = async () => {
    dispatch(setLiveBouquetData(getSelectedBouquetsForSection('live')))
    dispatch(setSerieBouquetData(getSelectedBouquetsForSection('series')))
    dispatch(setVodData(getSelectedBouquetsForSection('vod')))
    dispatch(toggleLiveBouquet());
    dispatch(toggleVodBouquet());
    dispatch(toggleSeriesBouquet());
    dispatch(setActiveStep(activeStep + 1))
  };

  //Translation

  const {t} = useTranslation('global')
  return (
    <div className="p-4 bg-slate-100 rounded-md my-10 px-5">
      <div className="flex justify-center mt-4 md:flex-row flex-col items-center w-full">
        <div className='flex md:w-full'>
          <button className="bg-Mybg my-1 w-1/2 md:w-full text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={selectAll}>{t("Checkout.LiveBouquets.SelectAll")}</button>
          <button className="bg-Mybg w-1/2 md:w-full my-1 text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={unselectAll}>{t("Checkout.LiveBouquets.UnSelectAll")}</button>
        </div>
        <div className='flex'>
          <button className="bg-Mybg w-1/4 my-1 text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortTop}><Top /></button>
          <button className="bg-Mybg w-1/4 my-1 text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortBottom}><Bottom /></button>
          <button className="bg-Mybg w-1/4 my-1 text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortMaxTop}><MaxTop /></button>
          <button className="bg-Mybg w-1/4 my-1 text-white px-4 py-2 mr-2 rounded-md hover:bg-MybgHover" onClick={sortMaxBottom}><MaxBottom /></button>
        </div>
      </div>
      <div className="flex mt-2 flex-col md:flex-row">
        <button className={`${selectedSection === 'live' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1`} onClick={() => handleSectionChange('live')}>Live Bouquets</button>
        <button className={`${selectedSection === 'vod' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1 md:mx-2 my-1 md:my-0`} onClick={() => handleSectionChange('vod')}>Vod</button>
        <button className={`${selectedSection === 'series' ? 'bg-Mybg' : 'bg-MybgHover'} hover-bg-Mybg text-white px-2 py-2 rounded-md flex-1`} onClick={() => handleSectionChange('series')}>Series Bouquets</button>
      </div>
      {
        loading 
        ? 
        <>
          <Loading />
        </>
        : 
        <div className="max-h-72 overflow-y-auto bg-white px-4 py-2 border-2 border-slate-300 rounded-md mt-2">
          <ul>
            {getBouquetsForSection().map((bouquet) => (
              <li key={bouquet._id} onClick={() => toggleSelection(bouquet._id)} className={`cursor-pointer py-4 px-4 border-2 border-Mybg my-1 ${selectedIds.includes(bouquet._id) ? 'bg-slate-200' : ''} hover-bg-slate-200 rounded-md`}>{bouquet.name}</li>
            ))}
          </ul>
        </div>
      }
      <div className="flex justify-center">
        <button className="bg-Mybg hover:bg-MybgHover rounded-md py-2 md:w-1/3 w-full text-white mt-2" onClick={handleSubmission}>
          {
            loadingSubmission ? <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : t("Checkout.LiveBouquets.button")
          }
        </button>
      </div>
    </div>
  );
};

export default LiveBouquet;