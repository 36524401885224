import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import NotFounds from '../../NotFound/NotFound';
import SignUp from '../SignUp/SignUp';
import SignIn from '../SignIn/SignIn';
import MainPage from '../MainPage/MainPage';
import Cookies from 'js-cookie';
import ServiceByName from '../ServiceByName/ServiceByName';
import Checkout from '../Checkout/Checkout';
import User from '../../Assets/Icons/User';
import { useDispatch } from 'react-redux';
import ConfirmationLogout from './ConfirmationLogout/ConfirmationLogout';
import Service from '../../Assets/Icons/Service';
import Cart from '../../Assets/Icons/Cart';
import { checkTokenValidity } from '../../Redux/Slices/AuthSlice';
import { useSelector } from 'react-redux';
import ServicesAuth from '../Services_For_Authentified/ServicesAuth';
import ProfileUser from '../Profile/ProfileUser';
import MySubscribtion from '../Checkout/MySubscribtion/MySubscribtion';
import UpdateSubscribtion from '../Checkout/MySubscribtion/updateSubscribtion/UpdateSubscribtion';
import Translation from '../../Assets/Icons/Translation';
import ToggleMenu from '../../Assets/Icons/ToggleMenu';
import Sparkles from '../../Assets/Icons/Sparkles';
import { useTranslation } from 'react-i18next';
import { fetchTheme } from '../../Redux/Slices/themeSlice';
import Francais from '../../Assets/Icons/Francais';
import Notification from '../../Assets/Icons/Notification';
import Anglais from '../../Assets/Icons/Anglais';
import LoadingTheme from '../../Assets/Loading/LoadingTheme';

const Header = () => {
    const [changeHeader, setChangeHeader] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChangeHeader = () => {
        if (window.scrollY >= 50) {
            setChangeHeader(true);
            setMenuVisible(false);
            setTranslationOpen(false);
        } else setChangeHeader(false);
    };
    window.addEventListener('scroll', onChangeHeader);
    //Token
    const token = useSelector((state) => state.auth.token);
    const reseller = useSelector((state) => state.auth.reseller);
    //check Token is Valide or not
    useEffect(() => {
        if (token) {
            if (!checkTokenValidity()) {
                // Le token n'est pas valide
                Cookies.remove('token');
                window.location.reload();
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [token]);

    const [isMenuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setTranslationOpen(false);
        setMenuVisible(!isMenuVisible);
    };

    // Toggle User
    const userRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!userRef.current || !userRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //modal confirmation SigOut
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const openConfirmation = () => {
        setConfirmationOpen(true);
        setMenuVisible(false);
    };

    const closeConfirmation = () => {
        setConfirmationOpen(false);
    };

    const handleConfirm = () => {
        closeConfirmation();
    };
    //toggle Translation menu
    const [TranslationOpen, setTranslationOpen] = useState(false);

    const toggleTranslation = () => {
        setMenuVisible(false);
        setTranslationOpen(!TranslationOpen);
    };
    // Toggle User
    const translationRef = useRef(null);
    useEffect(() => {
        const handleClickTranslationOutside = (event) => {
            if (!translationRef.current || !translationRef.current.contains(event.target)) {
                setTranslationOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickTranslationOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickTranslationOutside);
        };
    }, []);

    // Toggle reseller notifiation 
    const [resellerNotificationOpen , setResellerNotificationOpen] = useState(false)
    const toggleResellerNotification = () => {
        setMenuVisible(false)
        setTranslationOpen(false)
        setResellerNotificationOpen(!resellerNotificationOpen)
    }
    const resellerNotificationRef = useRef(null);
    useEffect(() => {
        const handleClickResellerNotification = (event) => {
            if (!resellerNotificationRef.current || !resellerNotificationRef.current.contains(event.target)) {
                setResellerNotificationOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickResellerNotification);

        return () => {
            document.removeEventListener('mousedown', handleClickResellerNotification);
        };
    }, []);

    //Translation Part
    const { t } = useTranslation("global");
    //Fonction pour changer langage
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(localStorage.getItem('language') || 'en');

    const changeLanguage = (newLang) => {
        setLang(newLang);
        i18n.changeLanguage(newLang);
        localStorage.setItem('language', newLang);
        setTranslationOpen(false);
    };

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [i18n, lang]);

    //Theme Switcher

    const dispatch = useDispatch();
    const { myBgColor, myBgHoverColor } = useSelector((state) => state.theme);

    useEffect(() => {
        dispatch(fetchTheme());
        document.documentElement.style.setProperty('--tw-bg-Mybg', myBgColor);
        document.documentElement.style.setProperty('--tw-bg-MybgHover', myBgHoverColor);
    }, [myBgColor, myBgHoverColor, dispatch]);

    document.title = process.env.REACT_APP_NAME;

    //Theme loading handler
    const themeState = useSelector((state) => state.theme);

    useEffect(() => {
        dispatch(fetchTheme());
    }, [dispatch]);

    if (themeState.loading) {
        return <LoadingTheme />;
    }

    return (
        <div>
            <ConfirmationLogout isOpen={isConfirmationOpen} onClose={closeConfirmation} onConfirm={handleConfirm} />
            <div>
                <div>
                    <header className={changeHeader ? "bg-white fixed z-50 top-0 left-0 w-full shadow-md transition duration-500" : "bg-transparent fixed z-50 top-0 left-0 w-full transition duration-500"}>
                        <nav className='flex items-center mx-auto px-2 py-4'>
                            <Link to={'/'}>
                                <p className='font-medium text-2xl ml-5'>{process.env.REACT_APP_NAME}</p>
                            </Link>
                            {/* Right Side */}
                            <div className='flex items-center absolute md:right-8 right-4'>
                                {
                                    token && reseller && (
                                        <div className='relative flex items-center' ref={resellerNotificationRef}>
                                            <button onClick={toggleResellerNotification} className='text-black hover:text-slate-800'>
                                                <Notification/>
                                            </button>
                                            {
                                                resellerNotificationOpen &&
                                                <div className='bg-black hover:bg-slate-800 flex flex-col justify-center items-center text-center absolute right-4 z-10 mt-28 w-60 mx-auto rounded-md py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-5'>
                                                    <div className='inline-flex items-center'>
                                                        <span className='text-yellow-300 mr-2'><Sparkles/></span>
                                                        <span className='text-yellow-300'>{t("header.NotificationReseller")}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                                <div className='relative flex items-center' ref={translationRef}>
                                    <button className='inline-flex mx-4' onClick={toggleTranslation} id='OpenTranslation'>
                                        <span>
                                            <Translation />
                                        </span>
                                        <span className='font-medium'>
                                            {lang}
                                        </span>
                                        <span>
                                            <ToggleMenu />
                                        </span>
                                    </button>
                                    {
                                        TranslationOpen &&
                                        <div className='bg-white flex flex-col justify-start items-start text-start absolute right-4 z-10 mt-36 w-36 origin-top-right rounded-md py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-1'>
                                            <button onClick={() => changeLanguage('en')} className='inline-flex hover:scale-x-105 rounded-md py-2 px-2 items-center'>
                                                <span className='mx-1'>
                                                    <Anglais />
                                                </span>
                                                English
                                            </button>
                                            <button onClick={() => changeLanguage('fr')} className='inline-flex items-center hover:scale-x-105 rounded-md py-2 px-2'>
                                                <span className='mx-1'>
                                                    <Francais />
                                                </span>
                                                Francais
                                            </button>
                                        </div>
                                    }
                                </div>
                                {
                                    token ?
                                    <div className='relative' ref={userRef}>
                                        <div className='bg-Mybg text-white cursor-pointer hover:MybgHover transition-all duration-300 py-2 px-2 rounded-full' onClick={toggleMenu}>
                                            <User />
                                        </div>
                                        {
                                            isMenuVisible &&
                                            <div className='bg-white flex flex-col justify-start items-center text-start absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-2' id='MenuVisible'>
                                                <Link to={'/profile'} onClick={() => { setMenuVisible(false) }} className='flex justify-start items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><User /></span>{t("header.Profile")}</Link>
                                                <Link to={'/'} onClick={() => { setMenuVisible(false) }} className='flex justify-start items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><Service /></span>{t("header.Services")}</Link>
                                                <Link to={'/mySubscribtion'} onClick={() => { setMenuVisible(false) }} className='flex justify-start items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700 mb-2'><span className='mr-3'><Cart /></span>{t("header.MySubscription")}</Link>
                                                <button onClick={openConfirmation} className='flex justify-center items-center w-full py-2 text-sm bg-Mybg hover:bg-MybgHover text-white rounded-md'>
                                                    {t("header.SignOut")}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <Link to={'/signIn'} className='ml-2 text-myColor hover:bg-MybgHover bg-Mybg transition-all duration-500 rounded-md py-[10px] px-5 font-medium'>
                                            {t("header.SignIn")}
                                        </Link>
                                    </div>
                                }
                            </div>
                        </nav>
                    </header>
                </div>
                <div className='mt-16 md:mt-0 lg:mt-16'>
                    <Routes>
                        <Route path='*' element={<NotFounds />} />
                        <Route path='/' element={token ? <ServicesAuth /> : <MainPage />} />
                        <Route path='/signIn' element={<SignIn />} />
                        <Route path='/signUp' element={<SignUp />} />
                        <Route path='/service/:id' element={<ServiceByName />} />
                        <Route path='/checkout/:id' element={<Checkout />} />
                        <Route path='/profile' element={token ? <ProfileUser /> : <SignIn />} />
                        <Route path='/mySubscribtion' element={token ? <MySubscribtion /> : <SignIn />} />
                        <Route path='/mySubscribtion/updateSubscribtion/:subscriptionId' element={token ? <UpdateSubscribtion /> : <SignIn />} />
                    </Routes>
                </div>
            </div>
            {/* <Footer/> */}
        </div>
    );
}

export default Header;