import React, { useEffect, useRef, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Credit from '../../Assets/Icons/Credit'
import Money from '../../Assets/Icons/Money'
import User from '../../Assets/Icons/User'
import Settings from '../../Assets/Icons/Settings'
import SignOut from '../../Assets/Icons/SignOut'
import Users from '../../Assets/Icons/Users'
import Service from '../../Assets/Icons/Service'
import Cart from '../../Assets/Icons/Cart'
import MainPageAdmin from '../MainPageAdmin/MainPageAdmin'
import Packages from '../Packages/Packages'
import { useDispatch , useSelector } from 'react-redux'
import AuthAdmin from '../Auth/AuthAdmin'
import Cookies from 'js-cookie'
import Services from '../Services/Services'
import Theme from '../Theme/Theme'
import SwitchTheme from '../../Assets/Icons/SwitchTheme'
import { fetchTheme } from '../../Redux/Slices/themeSlice'
import axios from 'axios'
import Profile from '../Settings/Profile'
import NotFounds from '../404/NotFound'
import UsersA from '../Users/Users'
import LoadingTheme from '../../Assets/Loading/LoadingTheme'
import ChannelsIcon from '../../Assets/Icons/Channels'
import Channels from '../Channels/Channels'
import Subscription from '../Subscription/Subscription'
import UpdateSubscription from '../Subscription/UpdateSubscription'
import ConfirmSignOut from './ConfirmSignOut'

const HeaderAdmin = () => {
    const [serviceByCredit , setServiceByCredit] = useState()

    const fetshServiceByCredit = async () => {
        try{
            const response = await axios.get(process.env.REACT_APP_SERVER + '/service/' , { 
                headers: {
                  'Authorization': `Bearer ${token_admin}`,
                  'Accept-Language' : localStorage.getItem('language')
                }
            })
            if(response.data){
                setServiceByCredit(response.data.data)
            }
        } catch ( error ) {
            console.error(error)
        }
    }

    useEffect(()=>{
        fetshServiceByCredit()
    } , [])

    //Changer navbar apartir transparent vers white
    const [changeHeader , setChangeHeader] = useState(false)
    const onChangeHeader = () =>{
        if(window.scrollY >= 50){
            setChangeHeader(true)
            setOpenCredit(false)
            setOpenUser(false)
        }else setChangeHeader(false)
    }
    window.addEventListener('scroll' , onChangeHeader);

    document.title = process.env.REACT_APP_NAME;;

    //Open Credit
    const [openCredit , setOpenCredit] = useState(false)
    const toggleCredit = () =>{
        setOpenUser(false)
        setOpenCredit(!openCredit)
    }
    // Toggke Credit
    const creditRef = useRef(null);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickCreditOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickCreditOutside);
        };
    }, []);
    const handleClickCreditOutside = (event) => {
        if (userRef.current && !userRef.current.contains(event.target)) {
            setOpenCredit(false);
        }
    };


    // Open User
    const [openUser , setOpenUser] = useState(false)
    const toggleUser = () =>{
        setOpenCredit(false)
        setOpenUser(!openUser)
    }
    // Toggle User
    const userRef = useRef(null);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (event) => {
        if (!userRef.current.contains(event.target) && !event.target.closest('.bg-white')) {
            setOpenUser(false);
        }
    };

    //import State from Admin Slice
    const token_admin = useSelector((state) => state.admin.token_admin);
    //sign Out
    const [openConfirmSignout , setConfirmSignout] = useState(false)
    const openConfirmationSignOut = () => {
        setConfirmSignout(true)
    }
    const closeConfirmSignout = () => {
        setConfirmSignout(false)
    }
    const handleConfirm = () => {
        closeConfirmSignout();
    };

    const dispatch = useDispatch();
    const { myBgColor, myBgHoverColor } = useSelector((state) => state.theme);

    useEffect(() => {
        dispatch(fetchTheme());
        document.documentElement.style.setProperty('--tw-bg-Mybg', myBgColor);
        document.documentElement.style.setProperty('--tw-bg-MybgHover', myBgHoverColor);
    } , [myBgColor, myBgHoverColor , dispatch]);

    //Theme loading handler
    const themeState = useSelector((state) => state.theme);

    useEffect(() => {
        dispatch(fetchTheme());
    }, [dispatch]);

    if (themeState.loading) {
        return <LoadingTheme/>;
    }

  return (
    <div>
        <ConfirmSignOut isOpen={openConfirmSignout} onClose={closeConfirmSignout} onConfirm={handleConfirm} />
        <div>
        <header className={changeHeader ? "bg-white fixed z-50 top-0 left-0 w-full shadow-md transition duration-500"  : "bg-transparent fixed z-50 top-0 left-0 w-full transition duration-500" }>
            <nav className='flex items-center mx-auto px-2 py-4'>
                <Link to={'/admin'}>
                    <p className='font-medium text-2xl ml-5 flex items-center'>{process.env.REACT_APP_NAME}&nbsp;<span className='bg-black text-white rounded-md py-1 px-2 text-sm'>A</span></p>
                </Link>
                {/* Right Side */}
                <div className='flex items-center absolute md:right-8 right-4'>
                    <button ref={creditRef} onClick={toggleCredit}  className='bg-Mybg rounded-full px-5 inline-flex py-2 items-center hover:bg-MybgHover translation-all'><Credit/>&nbsp;&nbsp;<span className='text-white'>Credit</span></button>
                    {
                        openCredit &&
                        <div className='bg-white flex flex-col justify-start items-center text-start absolute right-0 z-10 mr-14 mt-36 w-40 origin-top-right rounded-md py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-2'>
                            {serviceByCredit.map((serviceItem, index) => (
                                <button key={serviceItem.id} onClick={()=>{setOpenCredit(false)}}  className='flex justify-start items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700'>
                                    <Money/>&nbsp;&nbsp;{serviceItem.name} <span className='ml-2'>{serviceItem.credit}</span>
                                </button>
                            ))}
                        </div>
                    }
                    <div className='ml-4 bg-Mybg hover:bg-MybgHover text-white cursor-pointer transition-all py-2 px-2 rounded-full' onClick={toggleUser} ref={userRef}>
                        <User />
                    </div>
                    {
                        openUser &&
                        <div className='bg-white flex flex-col justify-start items-center text-start absolute right-0 z-10 mt-[370px] w-48 origin-top-right rounded-md py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-2'>
                            <Link onClick={()=>{setOpenUser(false)}} to={'/admin/setting'}  className='flex justify-start items-center w-full px-2 py-2.5 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><Settings /></span>Settings</Link>
                            <Link onClick={()=>{setOpenUser(false)}} to={'/admin/users'}  className='flex justify-start items-center w-full px-2 py-2.5 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><Users/></span>Users</Link>
                            <Link onClick={()=>{setOpenUser(false)}} to={'/admin/service'}  className='flex justify-start items-center w-full px-2 py-2.5 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><Service /></span>Services</Link>
                            <Link onClick={()=>{setOpenUser(false)}} to={'/admin/subscription'} className='flex justify-start items-center w-full px-2 py-2.5 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><Cart /></span>Subscribtion</Link>
                            <Link onClick={()=>{setOpenUser(false)}} to={'/admin/channel'} className='flex justify-start items-center w-full px-2 py-2.5 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><ChannelsIcon /></span>Bouquets</Link>
                            <Link onClick={()=>{setOpenUser(false)}} to={'/admin/switchTheme'} className='flex justify-start items-center w-full px-2 py-2 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><SwitchTheme /></span>Theme</Link>
                            <Link onClick={openConfirmationSignOut}  className='flex justify-start items-center hover:text-white w-full px-2 py-2.5 hover:bg-red-500 rounded-md text-sm text-gray-700'><span className='mr-3'><SignOut /></span>Sign Out</Link>
                        </div>
                    }
                </div>
            </nav>
        </header>
        </div>
        {/* Routes */}
        <div className='mt-16 md:mt-0 lg:mt-16'>
            <Routes>
                <Route path='/' element={token_admin ? <MainPageAdmin/> : <AuthAdmin/>} />
                <Route path='*' element={<NotFounds/>} />
                <Route path='/packages/:id' element={token_admin ? <Packages/> : <AuthAdmin/>} />
                <Route path='/service' element={token_admin ? <Services/> : <AuthAdmin/>} />
                <Route path='/switchTheme' element={token_admin ? <Theme/> : <AuthAdmin/>} />
                <Route path='/setting' element={token_admin ? <Profile/> : <AuthAdmin/>} />
                <Route path='/users' element={token_admin ? <UsersA/> : <AuthAdmin/>} />
                <Route path='/channel' element={token_admin ? <Channels/> : <AuthAdmin/>} />
                <Route path='/subscription' element={token_admin ? <Subscription/> : <AuthAdmin/>} />
                <Route path='/subscription/:subscriptionId/:userId' element={token_admin ? <UpdateSubscription/> : <AuthAdmin/>} />
            </Routes>
        </div>
    </div>
  )
}

export default HeaderAdmin