import React, { useState } from 'react'
import { motion } from 'framer-motion';
// import axios from 'axios';
import { useSelector } from 'react-redux';
import PaypalIcon from '../../../../Assets/Pics/PaypalIcon.png';
import CryptoIcon from '../../../../Assets/Pics/CryptoIcon.png';
import StripeIcon from '../../../../Assets/Pics/StripeIcon.png';
import GooglePayIcon from '../../../../Assets/Pics/GooglePayIcon.png';

const ModalExtendSubscription = ({isOpen, onClose , id }) => {
    const handleCancel = () => {
        onClose();
    };
    // const [loading , setLoading] = useState(false)
    const token = useSelector((state) => state.auth.token);
    // const [error , setError] = useState('')
    const [selectedMethod, setSelectedMethod] = useState(null);
    const handlePaymentMethodSelect = (method) => {
        setSelectedMethod(method);
    };

  return (
    <motion.div
        className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
    >
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px]">
                <p className="text-lg font-medium mb-4">Extend Subscription</p>
                <p className='text-md text-slate-500'>
                    This action allows you to pay or update a subscription payment.
                </p>
                <div className='flex justify-center items-center flex-col'>
                    <button
                        className={`hover:scale-105 w-full my-1 transition-all inline-flex items-center mx-2 border-2 border-slate-300 rounded-md ${
                            selectedMethod === 'PayPal' ? 'bg-slate-200' : ''
                        }`}
                        // onClick={() => stripePayment()}
                    >
                        <img src={PaypalIcon} alt="Paypal" className='w-[60px] p-2' />
                        <span className='hidden lg:block mx-10'>Paypal</span>
                    </button>
                    <button
                        disabled
                        className={`bg-slate-300 w-full my-1 transition-all inline-flex items-center mx-2 border-2 border-slate-300 rounded-md ${
                            selectedMethod === 'Crypto' ? 'bg-slate-200' : ''
                        }`}
                        onClick={() => handlePaymentMethodSelect('Crypto')}
                    >
                        <img src={CryptoIcon} alt="Cryptocurrency" className='w-[60px] p-2'/>
                        <span className='hidden lg:block mx-10'>Crypto</span>
                    </button>
                    <button
                        className={`hover:scale-105 w-full my-1 transition-all inline-flex items-center mx-2 border-2 border-slate-300 rounded-md ${
                            selectedMethod === 'Stripesa' ? 'bg-slate-200' : ''
                        }`}
                        // onClick={() => stripePayment()}
                    >
                        <img src={StripeIcon} alt="Stripe" className='w-[60px] p-2'/>
                        <span className='hidden lg:block mx-10'>Stripe</span>
                    </button>
                    <button
                        disabled
                        className={`bg-slate-300 w-full my-1 transition-all inline-flex items-center mx-2 py-2 md:py-[13px] border-2 border-slate-300 rounded-md ${
                            selectedMethod === 'GooglePay' ? 'bg-slate-200' : ''
                        }`}
                        onClick={() => handlePaymentMethodSelect('GooglePay')}
                    >
                        <img src={GooglePayIcon} alt="Google Pay" className='w-[60px] p-2'/>
                        <span className='hidden lg:block mx-10'>Google Pay</span>
                    </button>
                </div>
                <div className="flex justify-end mt-5">
                    <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </div>
    </motion.div>
  )
}

export default ModalExtendSubscription
