import axios from 'axios';
import React, { useState } from 'react'
import { squircle } from 'ldrs'
import { useSelector } from 'react-redux';

const ModalConfirmDelete = ({ isOpen, onClose, onConfirm , packageName  , id  , refereshData}) => {

    const token_admin = useSelector((state) => state.admin.token_admin);

    const [loading , setLoading] = useState(false)
    squircle.register()

    const handleCancel = () => {
        onClose();
    };
    
    const disablePackage = async () => {
        try {
            setLoading(true);
            onConfirm();
    
            const response = await axios.patch(`${process.env.REACT_APP_SERVER}/packages/disable/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            });
    
            if (response.data) {
                onClose();
                refereshData()
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };    
  return (
    <div className={`fixed  top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
        <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px]">
            <p className="text-lg font-medium mb-4 text-black">Confirm</p>
            <p className='text-md text-slate-500'>
                Are you sure you want to disable this <span className='text-Mybg font-medium'>{packageName}</span>? This action cannot be undone.
            </p>
        <div className="flex justify-end mt-10">
            <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded"onClick={handleCancel}>Cancel</button>
            <button className="bg-red-500 hover:bg-red-400 text-white px-4 py-2 rounded"onClick={disablePackage}>
                {loading ? <l-squircle size="18"stroke="4"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : 'Disable'}
            </button>
        </div>
        </div>
    </div>
  )
}

export default ModalConfirmDelete