import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation("global");
  return (
    <footer className="border-2 border-t-Mybg text-black py-4">
      <div className="container mx-auto text-center flex items-center">
        <p  className='flex md:px-0 px-3 md:justify-start mx-auto font-medium text-xl '>
          {process.env.REACT_APP_NAME}
        </p>
        <p className='mx-auto hidden md:block font-medium'>&copy; 2023 {process.env.REACT_APP_NAME}. {t("footer.text")}</p>
      </div>
    </footer>
  );
};

export default Footer;