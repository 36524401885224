import { createSlice } from '@reduxjs/toolkit';

const CheckoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        device_m3u : false ,
        device_mac : false ,
        device_activeCode : false ,
        m3u_password: '',
        m3u_userName: '',
        mac_mac_adress: '',
        activeCode_ActiveCode: '',
        vodBouquet: false,
        seriesBouquet: false,
        liveBouquet: false,
        liveBouquetData: [],
        serieBouquetData: [],
        vodData: [],
        activeStep: 0,
        latestPath : ''
    },
    reducers: {
        setDeviceM3u: (state) => {
            state.device_m3u = !state.device_m3u;
            state.device_mac = false;
            state.device_activeCode = false;
        },
        setDeviceMac: (state) => {
            state.device_mac = !state.device_mac;
            state.device_m3u = false;
            state.device_activeCode = false;
        },
        setDeviceActiveCode: (state) => {
            state.device_activeCode = !state.device_activeCode;
            state.device_mac = false;
            state.device_m3u = false;
        },
        setM3UPassword: (state, action) => {
            state.m3u_password = action.payload;
        },
        setM3UUserName: (state, action) => {
            state.m3u_userName = action.payload;
        },
        setMacMacAddress: (state, action) => {
            state.mac_mac_adress = action.payload;
        },
        setActiveCode: (state, action) => {
            state.activeCode_ActiveCode = action.payload;
        },
        toggleVodBouquet: (state) => {
            state.vodBouquet = !state.vodBouquet;
        },
        toggleSeriesBouquet: (state) => {
            state.seriesBouquet = !state.seriesBouquet;
        },
        toggleLiveBouquet: (state) => {
            state.liveBouquet = !state.liveBouquet;
        },
        setLiveBouquetData: (state, action) => {
            state.liveBouquetData = action.payload;
        },
        setSerieBouquetData: (state, action) => {
            state.serieBouquetData = action.payload;
        },
        setVodData: (state, action) => {
            state.vodData = action.payload;
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        setLatestPath: (state, action) => {
            state.latestPath = action.payload;
        },        
    },
});

export const {
    setM3UPassword,
    setM3UUserName,
    setMacMacAddress,
    setActiveCode,
    toggleVodBouquet,
    toggleSeriesBouquet,
    toggleLiveBouquet,
    setLiveBouquetData,
    setSerieBouquetData,
    setVodData,
    setActiveStep,
    setDeviceM3u, 
    setDeviceMac,
    setDeviceActiveCode,
    setLatestPath,
} = CheckoutSlice.actions;

export default CheckoutSlice.reducer;