import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { squircle } from 'ldrs'
import { useDispatch } from 'react-redux';
import { setDeviceMac, setMacMacAddress } from '../../../Redux/Slices/CheckoutSlice';
import { setActiveStep } from '../../../Redux/Slices/CheckoutSlice';

const Mac = () => {
    squircle.register()
    const [macAddress, setMacAddress] = useState('');
    const [loading , setLoading] = useState(false)
    const MAX_MAC_ADDRESS_LENGTH = 17;
    const activeStep = useSelector(state => state.checkout.activeStep);

    const isValidMacAddress = (mac) => {
        const macRegex = /^([0-9A-Fa-f]{2}[:-]?){5}([0-9A-Fa-f]{2})$/;
        return macRegex.test(mac);
    };

    const formatMacAddress = (mac) => {
        const sanitizedMac = mac.replace(/[^0-9A-Fa-f]/g, '');
        if (sanitizedMac !== '') {
            const formattedMac = sanitizedMac.match(/.{1,2}/g).join(':');
            return formattedMac;
        } else {
            return '';
        }
    };    

    const handleMacAddressChange = (e) => {
        const inputMacAddress = e.target.value;
        const formattedMacAddress = formatMacAddress(inputMacAddress);
        if (formattedMacAddress.length <= MAX_MAC_ADDRESS_LENGTH) {
            setMacAddress(formattedMacAddress);
        }
    };
    const dispatch = useDispatch();

    const SubmitMac = async () => {
        if (isValidMacAddress(macAddress)) {
            dispatch(setMacMacAddress(macAddress));
            dispatch(setActiveStep(activeStep + 1))
            dispatch(setDeviceMac())
        }
    };

    // Translation
    const { t } = useTranslation('global');

    return (
        <div className="flex mt-5 flex-col lg:flex-row items-center">
            <div className="lg:w-1/2 w-full flex flex-col lg:mx-10 my-2">
                <label className='md:mt-0 my-1'>{t("Checkout.DeviceType.mac.macAddress")}</label>
                <input
                    type="text"
                    className="rounded-md py-2 px-4 border-2 border-Mybg"
                    value={macAddress}
                    onChange={handleMacAddressChange}
                />
                <button
                    className={`py-2 mt-1 rounded-md text-white ${!macAddress ? 'bg-MybgHover' : 'bg-Mybg' }`}
                    disabled={!macAddress}
                    onClick={SubmitMac}
                >
                    {
                        loading 
                        ? 
                            <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> 
                        :
                            t("Checkout.DeviceType.mac.button")
                    }
                </button>
            </div>
            <div className="lg:w-1/2 w-full flex items-center mt-5 md:mt-0 lg:mx-10 my-2">
                <p className='text-center md:text-start'>
                    {t("Checkout.DeviceType.mac.description")}
                </p>
            </div>
        </div>
    );
};

export default Mac;