import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { squircle } from 'ldrs'
import Error from '../../Assets/Alert/Error';

const AddAdmin = () => {
    squircle.register()
    const [adminName, setAdminName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading , setLoading] = useState(false)
    const token_superadmin = useSelector((state) => state.superAdmin.token_superadmin);
    const [errorMsg , setErrorMsg] = useState()
    const navigate = useNavigate()

    const sendData = async () => {
        try {
            setLoading(true)
            const formData = new FormData();
            formData.append('adminName', adminName);
            formData.append('email', email);
            formData.append('password', password);
            const response = await axios.post(process.env.REACT_APP_SERVER + '/superAdmin/addAdmin' , formData , {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token_superadmin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            })
            if(response.data) {
                navigate('/superadmin')
            }
        } catch (error) {
            console.error('Error : ' , error)
            setErrorMsg(error.response.data.error)
        } finally {
            setLoading(false)
        }
    }

  return (
    <div className='flex w-full md:w-1/3 md:mx-auto px-4 flex-col mt-28 '>
        <div className='flex my-4 flex-col'>
            <h1 className='text-center text-xl'>
                Add Admin
            </h1>
        </div>
        {
            errorMsg && (
                <Error  className='my-2' message={errorMsg} />
            )
        }
        <div className='flex my-1 flex-col'>
            <label>Admin Name</label>
            <input type='text' value={adminName} onChange={(e)=>{setAdminName(e.target.value)}} className='rounded-md border-2 border-Mybg py-[4px] px-1'/>
        </div>
        <div className='flex my-1 flex-col'>
            <label>Email</label>
            <input type='text' value={email} onChange={(e)=>{setEmail(e.target.value)}} className='rounded-md border-2 border-Mybg py-[4px] px-1'/>
        </div>
        <div className='flex my-1 flex-col'>
            <label>Password</label>
            <input type='text' value={password} onChange={(e)=>{setPassword(e.target.value)}} className='rounded-md border-2 border-Mybg py-[4px] px-1'/>
        </div>
        <div className='flex my-1 flex-col'>
            <button className='bg-Mybg hover:bg-MybgHover rounded-md py-2 text-white' onClick={sendData}>
                {loading ? <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : 'Submit'}
            </button>
        </div>
    </div>
  );
};

export default AddAdmin;
