import React from 'react'

const Anglais = () => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 600" className='w-8 rounded-md'>
    <rect width="900" height="60" y="0" fill="#b22234"/>
    <rect width="900" height="60" y="120" fill="#b22234"/>
    <rect width="900" height="60" y="240" fill="#b22234"/>
    <rect width="900" height="60" y="360" fill="#b22234"/>
    <rect width="900" height="60" y="480" fill="#b22234"/>
    <rect width="300" height="240" fill="#3c3b6e"/>
    <polygon points="5,5 15,5 10,0" fill="#fff" transform="translate(25,25)"/>
    <polygon points="5,5 15,5 10,0" fill="#fff" transform="translate(55,25)"/>
    <polygon points="5,5 15,5 10,0" fill="#fff" transform="translate(85,25)"/>
    <polygon points="5,5 15,5 10,0" fill="#fff" transform="translate(40,45)"/>
    <polygon points="5,5 15,5 10,0" fill="#fff" transform="translate(70,45)"/>
  </svg>
  )
}

export default Anglais