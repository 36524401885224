import React from 'react'
import Warning from '../../../Assets/Icons/Warning'
import { useTranslation } from 'react-i18next';

const ModalWarning = ({ isOpen , onBack , Msg}) => {
    const handleBack = () => {
        onBack();
    };

    //Translation
    const {t} = useTranslation('global')
    return (
    <div className={`fixed  top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
        <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px]">
            <div className='flex justify-center items-center my-2'>
                <Warning/>
            </div>
            <p className='text-md text-slate-500 capitalize'>{t('Modal.WarningCheckout.description01')} <span className='text-Mybg'>{Msg}</span>, {t('Modal.WarningCheckout.description02')} <span className='text-Mybg'>"{t('Modal.WarningCheckout.description03')}"</span></p>
            <div className="flex justify-end mt-10">
                <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleBack}>{t('Modal.WarningCheckout.button')}</button>
            </div>
        </div>
    </div>
  )
}

export default ModalWarning