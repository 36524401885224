import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const SuperadminSlice = createSlice({
    name: 'admin',
    initialState: {
        token_superadmin: Cookies.get('token_superadmin') || null,
        isAuthenticated: !!Cookies.get('token_superadmin'),
    },
    reducers: {
        setTokenSuperAdmin: (state, action) => {
            state.token_superadmin = action.payload;
            state.isAuthenticated = true;
            Cookies.set('token_superadmin', action.payload);
        },
        cleartoken_Superadmin: (state) => {
            state.token_superadmin = null;
            state.isAuthenticated = false;
            Cookies.remove('token_superadmin');
        },
        signOut: (state) => {
            state.token_superadmin = null;
            state.isAuthenticated = false;
            Cookies.remove('token_superadmin');
            window.location.reload();
        },
    },
});

export const { actions, reducer } = SuperadminSlice;
export default SuperadminSlice;