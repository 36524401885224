import React, { useEffect, useRef, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import User from '../../Assets/Icons/User';
import SignOut from '../../Assets/Icons/SignOut';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTheme } from '../../Redux/Slices/themeSlice';
import LoadingTheme from '../../Assets/Loading/LoadingTheme';
import SignIn from '../Auth/Auth';
import MainPage from '../MainPage/MainPage';
import NotFound from '../404/NotFound';
import Service from '../../Assets/Icons/Service';
import Users from '../../Assets/Icons/Users';
import ServicePage from '../Service/Service'
import AddAdmin from '../AddAdmin/AddAdmin';
import ConfirmSignOut from './ConfirmSignout';

const Header = () => {
    // Changer navbar apartir transparent vers white
    const [changeHeader, setChangeHeader] = useState(false);
    const onChangeHeader = () => {
        if (window.scrollY >= 50) {
            setChangeHeader(true);
            setOpenUser(false);
        } else setChangeHeader(false);
    };
    window.addEventListener('scroll', onChangeHeader);

    document.title = 'Steam Super Admin';

    // Open User
    const [openUser, setOpenUser] = useState(false);
    const toggleUser = () => {
        setOpenUser(!openUser);
    };
    // Toggle User
    const userRef = useRef(null);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (event) => {
        if (!userRef.current.contains(event.target) && !event.target.closest('.bg-white')) {
            setOpenUser(false);
        }
    };

    //sign Out
    const [openConfirmSignout , setConfirmSignout] = useState(false)
    const openConfirmationSignOut = () => {
        setConfirmSignout(true)
    }
    const closeConfirmSignout = () => {
        setConfirmSignout(false)
    }
    const handleConfirm = () => {
        closeConfirmSignout();
    };

    const dispatch = useDispatch();

    // Move useSelector calls outside conditional blocks
    const themeState = useSelector((state) => state.theme);
    const { myBgColor, myBgHoverColor } = useSelector((state) => state.theme);
    const token_superadmin = useSelector((state) => state.superAdmin.token_superadmin);

    useEffect(() => {
        dispatch(fetchTheme());
    }, [dispatch]);

    useEffect(() => {
        document.documentElement.style.setProperty('--tw-bg-Mybg', myBgColor);
        document.documentElement.style.setProperty('--tw-bg-MybgHover', myBgHoverColor);
    }, [myBgColor, myBgHoverColor]);

    if (themeState.loading) {
        return <LoadingTheme />;
    }

    return (
        <div>
            <ConfirmSignOut isOpen={openConfirmSignout} onClose={closeConfirmSignout} onConfirm={handleConfirm} />
            <div>
                <header
                    className={
                        changeHeader
                        ? 'bg-white fixed z-50 top-0 left-0 w-full shadow-md transition duration-500'
                        : 'bg-transparent fixed z-50 top-0 left-0 w-full transition duration-500'
                    }
                >
                    <nav className='flex items-center mx-auto px-2 py-4'>
                        <Link to={'/superadmin'}>
                            <p className='font-medium text-2xl ml-5 flex items-center'>
                                {process.env.REACT_APP_NAME}&nbsp;<span className='bg-black text-white rounded-md py-1 px-2 text-sm'>Sa</span>
                            </p>
                        </Link>
                        {/* Right Side */}
                        <div className='flex items-center absolute md:right-8 right-4'>
                            <div
                                ref={userRef}
                                className='ml-4 bg-Mybg hover:bg-MybgHover text-white cursor-pointer transition-all py-2 px-2 rounded-full'
                                onClick={toggleUser}
                            >
                                <User />
                            </div>
                            {openUser && (
                                <div className='bg-white flex flex-col justify-start items-center text-start absolute right-0 z-10 mt-[200px] w-48 origin-top-right rounded-md py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-2'>
                                    <Link onClick={()=>{setOpenUser(false)}} to={'/superadmin/service'}  className='flex justify-start items-center w-full px-2 py-2.5 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><Service /></span>Services</Link>
                                    <Link onClick={()=>{setOpenUser(false)}} to={'/superadmin'}  className='flex justify-start items-center w-full px-2 py-2.5 hover:bg-slate-100 rounded-md text-sm text-gray-700'><span className='mr-3'><Users /></span>Admins</Link>
                                    <Link onClick={openConfirmationSignOut} className='flex justify-start items-center hover:text-white w-full px-2 py-2.5 hover:bg-red-500 rounded-md text-sm text-gray-700'><span className='mr-3'><SignOut /></span>Sign Out</Link>
                                </div>
                            )}
                        </div>
                    </nav>
                </header>
            </div>
            {/* Routes */}
            <div className='mt-16 md:mt-0 lg:mt-16'>
                <Routes>
                    <Route path='/' element={token_superadmin ? <MainPage /> : <SignIn />} />
                    <Route path='*' element={<NotFound />} />
                    <Route path='/service' element={token_superadmin ? <ServicePage/> : <SignIn/>} />
                    <Route path='/addAdmin' element={token_superadmin ? <AddAdmin/> : <SignIn/>} />
                </Routes>
            </div>
        </div>
    );
};

export default Header;
