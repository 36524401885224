import axios from 'axios';
import React from 'react'
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const DeleteUser = ({isOpen, onClose, onConfirm , id , fisrtName , lastName}) => {

    const handleCancel = () => {
        onClose();
    };

    const token_admin = useSelector((state) => state.admin.token_admin);

    const deleteUser = async () => {
        try{
            const response = await axios.put(process.env.REACT_APP_SERVER + `/user/banned/${id}`, {} ,{
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            })
            if(response.data){
                onConfirm();
            }
        } catch (error) {
            console.error(error)
        }
    }
  return (
    <motion.div 
    className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    initial={{ opacity: 0 }}
    animate={{ opacity: isOpen ? 1 : 0 }}
    transition={{ duration: 0.3 }}
    >
        <div className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px] w-full">
                <p className="text-lg font-medium mb-4">Delete {fisrtName}&nbsp;{lastName}</p>
                <p className='text-md text-slate-500'>
                    Are you sure you want to delete {fisrtName}?
                </p>
                <div className="flex justify-end mt-5">
                    <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button className="bg-red-500 hover:bg-red-500 text-white px-4 py-2 rounded" onClick={deleteUser}>
                        Banne
                    </button>
                </div>
            </div>
        </div>
    </motion.div>
  )
}

export default DeleteUser