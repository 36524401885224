import React, { useEffect, useState } from 'react'
import Users from '../AssetsAdmin/Users'
import Services from '../AssetsAdmin/Services'
import Wallet from '../AssetsAdmin/Wallet'
import ServicesA from "./Services/Services"
import axios from 'axios'
import { useSelector } from 'react-redux'
import { squircle } from 'ldrs'
import Packages from '../AssetsAdmin/Packages'
import { useNavigate } from 'react-router-dom'
import SubscriptionStatistic from './Charts/SubscriptionStatistic'
import UserAndSubscriptionStatistic from './Charts/UserAndSubscriptionStatistic'

const MainPageAdmin = () => {
    squircle.register()
    const [nbrUser , setNbrUser] = useState(0)
    const [nbrService , setNbrService] = useState(0)
    const [nbrPackage , setNbrPackage] = useState(0)
    const [nbrSubscription , setNbrSubscription] = useState(0)
    const [loading , setLoading] = useState(false)

    const token_admin = useSelector((state) => state.admin.token_admin);

    const fetshNbrUser = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_SERVER + '/user/count-user', {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            });
    
            if (response.data) {
                setNbrUser(response.data.count);
            }
        } catch (error) {
            console.error('Error ', error);
        } finally {
            setLoading(false);
        }
    };
    
    const fetshNbrService = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_SERVER + '/service/count-service', {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            });
    
            if (response.data) {
                setNbrService(response.data.count);
            }
        } catch (error) {
            console.error('Error ', error);
        } finally {
            setLoading(false);
        }
    };
    
    const fetshNbrPackages = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_SERVER + '/packages/count-packages', {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            });
    
            if (response.data) {
                setNbrPackage(response.data.count);
            }
        } catch (error) {
            console.error('Error ', error);
        } finally {
            setLoading(false);
        }
    };

    const fetshNbrSubscription = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_SERVER + '/subscription/count-subscription', {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            });
    
            if (response.data) {
                setNbrSubscription(response.data.totalSubscriptions);
            }
        } catch (error) {
            console.error('Error ', error);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            await fetshNbrUser();
            await fetshNbrPackages();
            await fetshNbrService();
            await fetshNbrSubscription();
        };
    
        fetchData();
    
        const intervalId = setInterval(() => {
            fetchData();
        }, 3 * 60 * 1000);
    
        return () => clearInterval(intervalId);
    }, []);
    
  return (
    <div className='container mx-auto mt-24'>
        <div className='flex flex-wrap mx-5 lg:mx-0'>
            <div className='flex-grow flex-shrink-0 lg:w-1/5 md:w-1/3 w-full px-10 mx-1 bg-slate-50 translation-all duration-500 rounded-md py-8 hover:shadow-lg my-1 lg:my-0 border-2 border-slate-200'>
            {
                    loading 
                    ?  
                        <l-squircle size="20" stroke="5" stroke-length="0.15" bg-opacity="0.1" speed="0.9" color="black" ></l-squircle>
                    :
                    <>
                        <div className='flex justify-between'>
                            <h1 className='flex justify-start items-center text-slate-500'>Packages</h1>
                            <span className='text-black'><Packages/></span>
                        </div>
                        <div className='flex justify-start'>
                            <p className='text-black text-xl '>{nbrPackage}</p>
                        </div>
                    </>
            }
            </div>
            <div className='flex-grow flex-shrink-0 lg:w-1/5 md:w-1/3 w-full px-10 mx-1 bg-slate-50 translation-all duration-500 rounded-md py-8 hover:shadow-lg my-1 lg:my-0 border-2 border-slate-200'>
                {
                    loading 
                    ?  
                        <l-squircle size="20" stroke="5" stroke-length="0.15" bg-opacity="0.1" speed="0.9" color="black" ></l-squircle>
                    :
                    <>
                        <div className='flex justify-between'>
                            <h1 className='flex justify-start items-center text-slate-500'>Users</h1>
                            <span className='text-black'><Users/></span>
                        </div>
                        <div className='flex justify-start'>
                            <p className='text-black text-xl '>{nbrUser}</p>
                        </div>
                    </>
                }
            </div>
            <div className='flex-grow flex-shrink-0 lg:w-1/5 md:w-1/3 w-full px-10 mx-1 bg-slate-50 translation-all duration-500 rounded-md py-8 hover:shadow-lg my-1 lg:my-0 border-2 border-slate-200'>
                {
                    loading 
                    ?  
                        <l-squircle size="20" stroke="5" stroke-length="0.15" bg-opacity="0.1" speed="0.9" color="black" ></l-squircle>
                    :
                    <div>
                        <div className='flex justify-between'>
                            <h1 className='flex justify-start items-center text-slate-500'>Services</h1>
                            <span className='text-black'><Services/></span>
                        </div>
                        <div className='flex justify-start'>
                            <p className='text-black text-xl '>{nbrService}</p>
                        </div>
                    </div>
                }
            </div>
            <div className='flex-grow flex-shrink-0 lg:w-1/5 md:w-1/3 w-full px-10 mx-1 bg-slate-50 translation-all duration-500 rounded-md py-8 hover:shadow-lg my-1 lg:my-0 border-2 border-slate-200'>
            {
                loading 
                ?  
                    <l-squircle size="20" stroke="5" stroke-length="0.15" bg-opacity="0.1" speed="0.9" color="black" ></l-squircle>
                :
                <>
                    <div className='flex justify-between'>
                        <h1 className='flex justify-start items-center text-slate-500'>Subscribtion</h1>
                        <span className='text-black'><Wallet/></span>
                    </div>
                    <div className='flex justify-start'>
                        <p className='text-black text-xl '>{nbrSubscription}</p>
                    </div>
                </>
            }
            </div>
        </div>
        {/* Chart */}
        <div className='flex my-2 mx-5 lg:mx-0 flex-wrap'>
            <div className='md:w-1/2 w-full flex-grow flex-shrink-0'>
                <UserAndSubscriptionStatistic />
            </div>
            <div className='md:w-1/2 w-full flex-grow flex-shrink-0'>
                <SubscriptionStatistic />
            </div>
        </div>
        {/* Services */}
        <div>
            <ServicesA/>
        </div>
    </div>
  )
}

export default MainPageAdmin