import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Package from '../../../Assets/Icons/Package';
import axios from 'axios';
import Loading from '../../../Assets/Loading/Loading';
import LoadingImg from '../../../Assets/Pics/loading_img.png'

const Services = () => {
    const [service , setService] = useState([])
    const [loading , setLoading] = useState(false)

    const fetshService = async() => {
        try {
            setLoading(true)
            const response = await axios.get(process.env.REACT_APP_SERVER + '/service/all')
            if(response.data){
                setService(response.data)
            }
        } catch (error) {
            console.error('Error fetching services:', error);

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetshService();

        const intervalId = setInterval(() => {
          fetshService();
        }, 3 * 60 * 1000);
      
        return () => clearInterval(intervalId);
    }, []);

    const navigate = useNavigate()
  return (
    <div className='flex flex-col'>
        <div className='flex justify-center items-center text-center mt-10 mx-auto flex-col'>
            <h1 className='text-xl text-center'>Services</h1>
        </div>
        <div className='flex flex-wrap mx-10 lg:mx-0 lg:justify-start justify-center lg:mb-10 mt-5'>
        {  
        loading 
            ? 
                <>
                    <Loading />
                    <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                        <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                    </div>
                </>
            : 
                <>
                    {service ? service.map((serviceItem, index) => (
                        <div key={serviceItem._id} className='flex-grow flex-shrink-0 w-full lg:w-1/4 my-1 cursor-pointer shadow-lg mx-1 md:my-1 bg-BgService rounded-md hover:bg-BgServiceHover transition-all duration-500'  onClick={() => { navigate(`packages/${serviceItem._id}`); }}>
                            <div className='text-center pt-10 md:pt-12 hover:scale-90 transition-all duration-500 '>
                                {
                                    serviceItem.ServicePicture
                                    ? (
                                        <>
                                            <img className='flex justify-center items-center mx-auto mt-5' src={`${process.env.REACT_APP_SERVER}/service_picture/${serviceItem.ServicePicture}`} />
                                            <h1 className='text-xl text-white'>{serviceItem.name}</h1>
                                        </>
                                    ) : (
                                        <>
                                            <h1 className='text-4xl mt-[20px] text-white'>{serviceItem.name}</h1>
                                        </>
                                    )
                                }
                                <div className='flex justify-end items-center mr-1 mt-[28px]'>
                                    <span className='text-white font-semibold'>{serviceItem.packageCount}&nbsp;</span>
                                    <Package />
                                </div>
                            </div>
                        </div>
                    )) : <img src={LoadingImg} />}
                </>
            }
        </div>
    </div>
  )
}

export default Services