import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const ConfirmationModel = ({ isOpen, onClose, onConfirm , ServiceName , PriceSubscription , id  , month}) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    onClose();
  };

  const handleSubscribe = () => {
    onConfirm();
    navigate(`/checkout/${id}`);
  };
  const { t } = useTranslation("global");

  return (
    <motion.div 
      className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: isOpen ? 1 : 0 }}
      transition={{ duration: 0.3 }}
    >
    <div className={`fixed  top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
      <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px]">
        <p className="text-lg font-medium mb-4">{t("ModelConfirmationSubscribtion.title")}</p>
        <p className='text-md text-slate-500'>
            {t("ModelConfirmationSubscribtion.description01")}<span className='text-Mybg font-medium'>{ServiceName}</span> {t("ModelConfirmationSubscribtion.description02")}<span className='text-Mybg font-medium'>{PriceSubscription}</span> {t("ModelConfirmationSubscribtion.description03")}<span className='text-Mybg font-medium'>{month}</span> {t("ModelConfirmationSubscribtion.description04")}
        </p>
        <div className="flex justify-end mt-10">
          <button
            className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded"
            onClick={handleCancel}
          >
            {t("ModelConfirmationSubscribtion.cancel")}
          </button>
          <button
            className="bg-Mybg hover:bg-MybgHover text-white px-4 py-2 rounded"
            onClick={handleSubscribe}
          >
            {t("ModelConfirmationSubscribtion.subscribe")}
          </button>
        </div>
      </div>
    </div>
    </motion.div>
  );
};

export default ConfirmationModel;
