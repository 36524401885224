import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../Slices/AuthSlice';
import adminSlice from '../Slices/AdminSlice';
import themeReducer from '../Slices/themeSlice';
import CheckoutReducer from '../Slices/CheckoutSlice';
import SuperadminSlice from '../Slices/SuperAdminSlice';


const store = configureStore({
  reducer: {
    auth: authReducer,
    admin: adminSlice.reducer,
    theme: themeReducer,
    checkout: CheckoutReducer,
    superAdmin: SuperadminSlice.reducer,

  },
});

export default store;
