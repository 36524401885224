import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Error from '../../Assets/Alert/Error'

const ConfirmResetPassword = ({ isOpen , onClose , userId}) => {
    const handleCancel = () => {
        onClose();
    };
    const token_admin = useSelector((state) => state.admin.token_admin);
    const [errMsg, setErrorMsg] = useState('')
    const PasswordReset = async ( ) => {
        try{
            const response = await axios.post(process.env.REACT_APP_SERVER + `/user/password-reset/${userId}`, {} ,{
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            })
            if(response.data){
                onClose()
            }
        } catch (error) {
            setErrorMsg(error.response.data.error)
        }
    } 
    return (
        <motion.div 
            className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: isOpen ? 1 : 0 }}
            transition={{ duration: 0.3 }}
        >
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px]">
                <p className="text-lg font-medium mb-4">Confirm Reset Password</p>
                {errMsg && (
                    <Error message={errMsg} />
                )}
                <p className='text-md text-slate-500'>
                    This action can reset the password for the user by sending their new password to their email.
                </p>
                <div className="flex justify-end mt-5">
                    <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button className="bg-Mybg hover:bg-MybgHover text-white px-4 py-2 rounded" onClick={PasswordReset}>
                        Reset
                    </button>
                </div>
            </div>
        </motion.div>
    );
}

export default ConfirmResetPassword;