import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    token_admin: Cookies.get('token_admin') || null,
    isAuthenticated: !!Cookies.get('token_admin'),
  },
  reducers: {
    setTokenAdmin: (state, action) => {
      state.token_admin = action.payload;
      state.isAuthenticated = true;
      Cookies.set('token_admin', action.payload);
    },
    cleartoken_admin: (state) => {
      state.token_admin = null;
      state.isAuthenticated = false;
      Cookies.remove('token_admin');
    },
    signOut: (state) => {
      state.token_admin = null;
      state.isAuthenticated = false;
      Cookies.remove('token_admin');
      window.location.reload();
    },
  },
});

export const { actions, reducer } = adminSlice;
export default adminSlice;