import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Delete from '../../Assets/Icons/Delete'
import ConfirmDeleteAdmin from './ConfirmDeleteAdmin';
import Loading from '../../Assets/Loading/Loading';
import LoadingImg from '../../Assets/Pics/loading_img.png'
import Add from '../../Admin/AssetsAdmin/Add'
import { useNavigate } from 'react-router-dom';
import Update from '../../Assets/Icons/Update';
import Send from '../../Assets/Icons/Send';
import UpdateAdmin from './UpdateAdmin';

const MainPage = () => {
  const [admins, setAdmins] = useState([]);
  const token_superadmin = useSelector((state) => state.superAdmin.token_superadmin);
  const [loading , setLoading] = useState(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_SERVER}/admin/allAdmins` , {
        headers: {
          'Authorization' : `Bearer ${token_superadmin}`,
          'Accept-Language' : localStorage.getItem('language')
        }
      });
      setAdmins(response.data);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }finally{
      setLoading(false)
    }
  };

  const PasswordReste = async (id) => {
    try{
        const response = await axios.post(process.env.REACT_APP_SERVER + `/admin/password-reset/${id}`, {} ,{
          headers: {
            'Authorization': `Bearer ${token_superadmin}`,
            'Accept-Language' : localStorage.getItem('language')
          }
        })
        if(response.data){
          alert(response.data.message)
        }
    } catch (error) {
      alert(error.response.data.error)
      console.error(error)
    }
  } 

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: 'Admin Name',
      selector: 'adminName',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Last Connexion',
      selector: 'lastConnect',
      sortable: true,
      cell: (row) => {
        const formattedDate = row.lastConnect ? row.lastConnect.split("T")[0] : '';
        const formattedTime = row.lastConnect ? row.lastConnect.split("T")[1].split(".")[0] : '';
        return (
        <div>
          {formattedDate && formattedTime ? (
            <span>
              {formattedDate} {formattedTime}
            </span>
          ) : (
            <span>
              -
            </span>
          )}
        </div>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center items-center space-x-2">
          <button onClick={()=>{openConfirmation(row.adminName , row._id)}}  className='rounded-md text-sm text-red-500 hover:text-red-400 p-1'><Delete /></button>
          <button onClick={()=>{openUpdateUser(row.adminName , row._id)}} className='rounded-md text-sm text-yellow-500 hover:text-yellow-400 p-1'><Update /></button>
          <button onClick={()=>{PasswordReste(row._id)}} className='rounded-md text-sm text-green-500 hover:text-green-400 p-1'><Send /></button>
        </div>
      ),
    },
  ];
  //Model Delete Subscription
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [adminName , setAdminName] = useState();
  const [adminId , setAdminId] = useState()
  const openConfirmation = ( adminName , adminId ) => {
    setAdminName(adminName)
    setAdminId(adminId)
    setConfirmationOpen(true);
  };
  
  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };
    
  const handleConfirm = () => {
    closeConfirmation();
  };
  const navigate = useNavigate()

  //Modal update user
  const [isUpdateAdminOpen , setUpdateAdminOpen] = useState(false);

  const openUpdateUser = (AdminName , id) => {
    setAdminName(AdminName)
    setAdminId(id)
    setUpdateAdminOpen(true);
  };
    
  const closeUpdateAdmin = () => {
    setUpdateAdminOpen(false);
  }

  return (
    <div className='flex flex-col mx-2 lg:mx-20'>
      <div className='flex md:items-start items-center mt-10 flex-col text-center md:text-start'>
        <h1 className='text-2xl text-start'>All Admins</h1>
        <p className='text-slate-500'>Admins Management Interface.</p>
      </div>
      <ConfirmDeleteAdmin isOpen={isConfirmationOpen} onClose={closeConfirmation}  onConfirm={handleConfirm} adminName={adminName} id={adminId} fetchData={fetchData} />
      <UpdateAdmin isOpen={isUpdateAdminOpen} onClose={closeUpdateAdmin} adminId={adminId} AdminName={adminName} refereshData={fetchData}/>
      {
        loading ? (
          <>
            <Loading />
            <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
              <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
            </div>
          </>
        ) : (
          <div className='mt-5'>
            <div className='flex justify-end'>
              <button className='bg-Mybg hover:bg-MybgHover rounded-md py-2 px-10 my-1' onClick={()=>{navigate('/superadmin/addAdmin')}}>
                <Add/>
              </button>
            </div>
            <DataTable
              columns={columns}
              data={admins}
              pagination
              sort
              className="border border-gray-300 rounded-lg shadow-lg overflow-hidden"
              striped
              highlightOnHover
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30]}
            />
          </div>
        )
      }
    </div>
  );
};

export default MainPage;