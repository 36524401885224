import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ActiveCodeIcon from '../../../Assets/Icons/ActiveCodeIcon';
import Save from '../../../Assets/Icons/Save';
import { useSelector } from 'react-redux';
import { squircle } from 'ldrs'
import { useDispatch } from 'react-redux';
import { setDeviceM3u, setM3UPassword, setM3UUserName } from '../../../Redux/Slices/CheckoutSlice';
import { setActiveStep } from '../../../Redux/Slices/CheckoutSlice';

//Supprimer O , o , 0 , l , I

const characters = 'abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789';

const generateRandomString = (length) => {
  return Array.from({ length }, () => characters[Math.floor(Math.random() * characters.length)]).join('');
};

const M3u = () => {
  squircle.register()
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const generateCredentials = () => {
    const generatedUserName = generateRandomString(8);
    const generatedPassword = generateRandomString(8);

    setUserName(generatedUserName);
    setPassword(generatedPassword);
  };

  useEffect(() => {
    generateCredentials();
    const intervalId = setInterval(() => {
      generateCredentials();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const [loading , setLoading] = useState(false)
  const activeStep = useSelector(state => state.checkout.activeStep);
  const dispatch = useDispatch();

  const SubmitM3u = async () => {
    dispatch(setM3UPassword(password));
    dispatch(setM3UUserName(userName));
    dispatch(setDeviceM3u());
    dispatch(setActiveStep(activeStep + 1))
  };

  // Translation
  const { t } = useTranslation('global');

  return (
    <div className='flex mt-5 flex-col lg:flex-row'>
      <div className="lg:w-1/2 w-full flex flex-col lg:mx-10 my-2">
        <label className='md:mt-0 my-1'>{t("Checkout.DeviceType.m3u.userName")}</label>
        <input type='text' className='rounded-md py-2 px-4 border-2 border-Mybg' value={userName} readOnly />
        <label className='md:mt-0 my-1'>{t("Checkout.DeviceType.m3u.password")}</label>
        <input type='text' className='rounded-md py-2 px-4 border-2 border-Mybg' value={password} readOnly />
        <div className='flex items-center justify-center'>
          <button className="w-1/2 py-2 mt-1 rounded-md text-white hover:bg-MybgHover bg-Mybg mx-1 flex justify-center" onClick={generateCredentials}><ActiveCodeIcon/></button>
          <button className="w-1/2 py-2 mt-1 rounded-md text-white hover:bg-MybgHover bg-Mybg mx-1 flex justify-center" onClick={SubmitM3u}>
          {loading 
            ?
            <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> 
            : 
            <Save />
          }
          </button>
        </div>
      </div>
      <div className="lg:w-1/2 w-full flex items-center mt-5 md:mt-0 lg:mx-10 my-2">
        <p className='text-center md:text-start'>{t("Checkout.DeviceType.m3u.description")}</p>
      </div>
    </div>
  );
};

export default M3u;