import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import Details from '../../../Assets/Icons/Details';
import Banned from '../../../Assets/Icons/Banned';
import AuthorizeUser from '../../../Assets/Icons/AuthorizeUser';
import DetailsUser from '../DetailsUser';
import ConfirmeDeleteUser from '../BanneUser'
import Loading from '../../../Assets/Loading/Loading';
import LoadingImg from '../../../Assets/Pics/loading_img.png'
import Search from '../../../Assets/Icons/Search'
import Update from '../../../Assets/Icons/Update'
import Send from '../../../Assets/Icons/Send'
import UpdateUser from '../UpdateUser';
import ConfirmResetPassword from '../ConfirmResetPassword';

const Table = () => {
  const [userData, setUserData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const token_admin = useSelector((state) => state.admin.token_admin);
  const [loading , setLoading] = useState(false)
  const [refresh, setRefesh] = useState(0);

  const refereshData = async () => {
      await setRefesh(refresh + 1);
  };

  const fetshUsers = async () => {
    try {
      setLoading(true)
      const response = await axios.get(process.env.REACT_APP_SERVER + '/user/all-user', {
        headers: {
          'Authorization': `Bearer ${token_admin}`,
          'Accept-Language': localStorage.getItem('language')
        }
      })

      if (response.data) {
        setUserData(response.data.users);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetshUsers();

    const intervalId = setInterval(() => {
        fetshUsers();
    }, 3 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [refresh]);

  
  const authorizeUser = async (id) => {
    try{
        const response = await axios.put(process.env.REACT_APP_SERVER + `/user/authorize/${id}`, {} ,{
            headers: {
              'Authorization': `Bearer ${token_admin}`,
              'Accept-Language' : localStorage.getItem('language')
            }
        })
        if(response.data){
          fetshUsers();
        }
    } catch (error) {
        console.error(error)
    }
  } 

  const PasswordReste = async (id) => {
    try{
        const response = await axios.post(process.env.REACT_APP_SERVER + `/user/password-reset/${id}`, {} ,{
          headers: {
            'Authorization': `Bearer ${token_admin}`,
            'Accept-Language' : localStorage.getItem('language')
          }
        })
        if(response.data){
          alert(response.data.message)
        }
    } catch (error) {
      alert(error.response.data.error)
      console.error(error)
    }
  } 

  const columns = [
    { name: 'First Name', selector: (row) => row.FirstName, sortable: true },
    { name: 'Last Name', selector: (row) => row.LastName, sortable: true },
    { name: 'Phone', selector: (row) => row.phone, sortable: true },
    {
      name: 'Banned',
      cell: (row) => (
        <span className={row.banned ? 'text-red-500' : 'text-green-500'}>
          {row.banned ? 'Banned' : 'Not banned'}
        </span>
      ),
    },
    {
      name: 'Created',
      selector: (row) => <span>{row.created.split("T")[0]}</span>,
      sortable: true,
    },
    {
      name: 'Subscription',
      selector: (row) => <span>{row.subscriptionCount}</span>,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center items-center space-x-1">
          {
            row.banned ? (
              <button onClick={()=>{authorizeUser(row._id)}}  className='rounded-md text-sm text-green-500 hover:text-green-400 p-1'><AuthorizeUser /></button>
            ) : (
              <button onClick={()=>{openConfirmation(row.FirstName , row.LastName , row._id)}}  className='rounded-md text-sm text-red-500 hover:text-red-400 p-1'><Banned /></button>
            )
          }
          <button onClick={()=>{openDetail(row.FirstName , row.LastName , row.Country , row.phone , row.email , row.subscriptionCount , row.confirmed)}}  className='rounded-md text-sm text-blue-500 hover:text-blue-400 p-1'><Details /></button>
          <button onClick={()=>{openUpdateUser(row.FirstName , row.LastName , row._id)}} className='rounded-md text-sm text-yellow-500 hover:text-yellow-400 p-1'><Update/></button>
          <button onClick={()=>{openConfirmationReset(row._id)}} className='rounded-md text-sm text-green-500 hover:text-green-400 p-1'><Send/></button>
        </div>
      ),
    },
  ];  

  const filteredData = userData.filter(user =>
    user.FirstName.toLowerCase().includes(searchText.toLowerCase()) ||
    user.LastName.toLowerCase().includes(searchText.toLowerCase())
  );

  // Confirm reset password
  const [isConfirmResetOpen , setConfirmationResetOpen] = useState(false)
  const openConfirmationReset = ( id ) => {
    setUserId(id)
    setConfirmationResetOpen(true)
  }
  const closeConfirmationReset = () => {
    setConfirmationResetOpen(false)
  }

    //Model Details
  const [isDetailOpen , setIsDetailOpen] = useState(false)
  const [detailFisrtName , setDetailFirstName] = useState()
  const [detailLastName , setDetailLastName] = useState()
  const [detailCountry , setDetailCountry] = useState()
  const [detailPhone , setDetailPhone] = useState()
  const [detailEmail , setDetailEmail] = useState()
  const [detailCountSub , setDetailCountSub] = useState()
  const [detailConfirmed , setDetailConfirmed] = useState()

  const openDetail = (fisrtName , lastName , country , phone , email , countSub , confirmed) => {
    setDetailFirstName(fisrtName)
    setDetailLastName(lastName)
    setDetailCountry(country)
    setDetailPhone(phone)
    setDetailEmail(email)
    setDetailCountSub(countSub)
    setIsDetailOpen(true);
    setDetailConfirmed(confirmed)
};
    
  const closeDetail = () => {
    setIsDetailOpen(false);
  };

  //Modal confirm user
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [fisrtName , setFirstName] = useState()
  const [lastName , setLastName] = useState()
  const [userId , setUserId] = useState()

  const openConfirmation = (fisrtName , lastName , id) => {
    setFirstName(fisrtName)
    setLastName(lastName)
    setUserId(id)
    setConfirmationOpen(true);
  };
    
  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };
    
  const handleConfirm = () => {
    closeConfirmation();
    refereshData()
  }

  //Modal update user
  const [isUpdateUserOpen , setUpdateUserOpen] = useState(false);

  const openUpdateUser = (fisrtName , lastName , id) => {
    setFirstName(fisrtName)
    setLastName(lastName)
    setUserId(id)
    setUpdateUserOpen(true);
  };
    
  const closeUpdateUser = () => {
    setUpdateUserOpen(false);
  };

  return (
    <div>
      <ConfirmResetPassword isOpen={isConfirmResetOpen} onClose={closeConfirmationReset} userId={userId} />
      <ConfirmeDeleteUser isOpen={isConfirmationOpen} onClose={closeConfirmation} onConfirm={handleConfirm} id={userId} fisrtName={fisrtName} lastName={lastName}/>
      <DetailsUser isOpen={isDetailOpen} onClose={closeDetail} fisrtName={detailFisrtName} lastName={detailLastName} country={detailCountry} phone={detailPhone} email={detailEmail} countSubscription={detailCountSub} confirmed={detailConfirmed}/>
      <UpdateUser isOpen={isUpdateUserOpen} onClose={closeUpdateUser} userId={userId} FistName={fisrtName} LastName={lastName} refereshData={refereshData}/>
      <div>
        {
          loading ? (
            <>
              <Loading />
              <div className='flex justify-center items-center mx-auto'>
                  <div className='h-[300px] w-[300px]'>
                    <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                  </div>
              </div>
            </>
          ) : (
        <>
          <div className="relative">
              <input
                type="text"
                placeholder="Search by First Name or Last Name"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className='my-2 border-2 border-Mybg rounded-md py-2 px-2 w-full md:w-1/4 pl-8 shadow-md' 
              />
              <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                <span className='text-Mybg'>
                  <Search/>
                </span>
              </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            sort
            className="border border-gray-300 rounded-lg shadow-lg overflow-hidden"
            striped
            highlightOnHover
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30]}
          />
        </>
        )
      }
      </div>
    </div>
  )
}

export default Table;