import React from 'react'
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';

const ConfirmSignOut = ({isOpen, onClose, onConfirm}) => {
    const handleCancel = () => {
        onClose();
    };

    const handleSignOut = () => {
        onConfirm();
        const cookies = Cookies.get();
        for (const cookie in cookies) {
            Cookies.remove(cookie);
        }
        window.location.reload();
        window.location.href='/'
    };
  return (
    <motion.div 
        className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
    >
        <div className={`fixed top-0 left-0 w-full h-full z-50 backdrop-sm bg-backgroundConfirmationModel flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-5 md:mx-0 mx-2 rounded-md shadow-md md:w-[600px]">
            <p className="text-lg font-medium mb-4">Logout</p>
            <p className='text-md text-slate-500'>
                Are you sure you want to logout?
            </p>
            <div className="flex justify-end mt-5">
                <button className="bg-slate-100 hover:bg-slate-200 px-4 py-2 mr-2 rounded" onClick={handleCancel}>
                    Cancel
                </button>
                <button className="bg-Mybg hover:bg-MybgHover text-white px-4 py-2 rounded" onClick={handleSignOut}>
                    SignOut
                </button>
            </div>
            </div>
        </div>
    </motion.div>
  )
}

export default ConfirmSignOut