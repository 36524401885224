import React, { useState } from 'react'
import { squircle } from 'ldrs'
import Cookies from 'js-cookie'
import Hide from '../../Assets/Icons/Hide'
import Show from '../../Assets/Icons/Show'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Success from '../../Assets/Alert/Success'
import Error from '../../Assets/Alert/Error'
import Profil from '../../Assets/Icons/Profile'
squircle.register()

const Profile = () => {
    const [showNewPassword , setShowNewPassword] = useState(false)
    const [showConfirmPassword , setShowConfirmPassword] = useState(false)
    const toggleNewPasswordVisibilty = () =>{
        setShowNewPassword(!showNewPassword)
    }
    const toggleConfirmPasswordVisibilty = () =>{
        setShowConfirmPassword(!showConfirmPassword)
    }
    const [adminName , setAdminName] = useState(Cookies.get('Admin_Name') || '')
    const [email , setEmail] = useState(Cookies.get('Admin_Email') || '')
    const [OldPassword , setOldPassword] = useState('')
    const [NewPassword , setNewPassword] = useState('')
    const [ConfirmPassword , setConfirmPassword] = useState('')
    const [loading , setLoading] =useState(false)
    const [successMessage , setSuccessMessage] = useState()
    const [errMessage , setErrMessage] = useState()

    const getAdminName = (e) => {
        setAdminName(e.target.value)
    }
    const getEmail = (e) => {
        setEmail(e.target.value)
    }
    const getOldPassword = (e) => {
        setOldPassword(e.target.value)
    }
    const getNewPassword = (e) => {
        setNewPassword(e.target.value)
    }
    const getConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }

    const dispatch = useDispatch();
    const token_admin = useSelector((state) => state.admin.token_admin);

    const [idAdmin , setIdAdmin] = useState(Cookies.get('Admin_Id') || '')

    const updateProfileAdmin = async () =>{
        try{
            setLoading(true)
            const formData = new FormData();
        
            formData.append('adminName', adminName);
            formData.append('email', email);
            formData.append('currentPassword', OldPassword);
            formData.append('newPassword', NewPassword);
            formData.append('confirmPassword', ConfirmPassword);
            const response = await axios.put(`${process.env.REACT_APP_SERVER}/admin/profile/${idAdmin}` , formData  , {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${token_admin}`,
                'Accept-Language' : localStorage.getItem('language')
            },
            })
        
            if(response.data){
                setSuccessMessage(response.data.message)
                Cookies.set('Admin_Email' , response.data.user.email)
                Cookies.set('FirstName' , response.data.user.FirstName)
                Cookies.set('LastName' , response.data.user.LastName)
                Cookies.set('token_admin' , response.data.token)
            }
        } catch (error) {
            console.error(error)
            setErrMessage(error.response.data.error);
      } finally{
            setLoading(false)
      }
    }
  return (
    <div className='flex items-center justify-center flex-col my-20'>
        <div className='mx-auto flex justify-center items-center'>
            <span className='text-white hover:rotate-12 transition-all duration-500'><Profil/></span>
        </div>
        {
          successMessage && <div className='flex flex-col w-full px-2 md:mx-0 md:w-1/3 my-2'><Success message={successMessage} /></div>
        }
        {
          errMessage && <div className='flex flex-col w-full px-2 md:mx-0 md:w-1/3 my-2'><Error message={errMessage} /></div>
        }
        <div className='flex flex-col w-full px-2 md:mx-0 md:w-1/3 my-2'>
            <label>Admin Name</label>
            <input type="text" value={adminName} onChange={getAdminName} className='py-2 px-1.5 border-2 border-Mybg rounded-md' />
        </div>
        <div className='flex flex-col w-full px-2 md:mx-0 md:w-1/3 my-2'>
            <label>Admin Email</label>
            <input type="email" value={email} onChange={getEmail} className='py-2 px-1.5 border-2 border-Mybg rounded-md' />
        </div>
        <div className='flex flex-col w-full px-2 md:mx-0 md:w-1/3 my-2'>
            <label>Old Password</label>
            <input type="password" value={OldPassword} onChange={getOldPassword} className='py-2 px-1.5 border-2 border-Mybg rounded-md' />
        </div>
        <div className='flex flex-col w-full px-2 md:mx-0 md:w-1/3 my-2 relative'>
            <label>New Password</label>
            <input type={showNewPassword ? 'text' : 'password'} value={NewPassword} onChange={getNewPassword} className='py-2 px-1.5 border-2 border-Mybg rounded-md' />
            <span className="absolute top-[28px] right-4 cursor-pointer" onClick={toggleNewPasswordVisibilty}>
                {showNewPassword ? <Hide /> : <Show />}
            </span>
        </div>
        <div className='flex flex-col w-full px-2 md:mx-0 md:w-1/3 my-2 relative'>
            <label>Confirm New Password</label>
            <input type={showConfirmPassword ? 'text' : 'password'} value={ConfirmPassword} onChange={getConfirmPassword} className='py-2 px-1.5 border-2 border-Mybg rounded-md' />
            <span className="absolute top-[28px] right-4 cursor-pointer" onClick={toggleConfirmPasswordVisibilty}>
                {showConfirmPassword ? <Hide /> : <Show />}
            </span>
        </div>
        <div className='flex flex-col w-full px-2 md:mx-0 md:w-1/3 my-2'>
            <button onClick={updateProfileAdmin} className='rounded-md py-2 bg-Mybg hover:bg-MybgHover text-white'>
            {
                loading 
                ?
                <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle>
                :
                'Submit'
            }
            </button>
        </div>
        
    </div>
  )
}

export default Profile