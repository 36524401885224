import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from '../../../Assets/Loading/Loading';
import BarChart from './BarChart';
import { useSelector } from 'react-redux';

const UserAndSubscriptionStatistic = () => {
    const [apiData, setApiData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
            fetchData();
        }, 3 * 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const token_admin = useSelector((state) => state.admin.token_admin);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_SERVER + '/statistic/user-sub', {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language': localStorage.getItem('language')
                }
            });
            if (response.data) {
                setApiData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='px-10 mx-1 bg-slate-50 translation-all duration-500 rounded-md py-8 hover:shadow-lg my-1 lg:my-0 border-2 border-slate-200'>
            {loading ? (
                <Loading />
            ) : apiData ? (
                <BarChart data={apiData} />
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

export default UserAndSubscriptionStatistic;