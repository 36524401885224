import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Hide from '../../Assets/Icons/Hide'
import Show from '../../Assets/Icons/Show'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import Background from '../../Assets/Pics/Background'
import Warning from '../../Assets/Alert/Warning'
import Success from '../../Assets/Alert/Success'
import { squircle } from 'ldrs'
import Error from '../../Assets/Alert/Error'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

const SignUp = () => {
    const [showPassword , setShowPassword] = useState(false)
    const togglePasswordVisibilty = () =>{
        setShowPassword(!showPassword)
    }

    squircle.register()
    const [email , setEmail] = useState()
    const [cgeo,setCgeo]= useState()
    const [password , setPassword] = useState()
    const [FirstName , setFirstName] = useState()
    const [LastName , setLastName] = useState()
    const [phone , setPhone] = useState()
    const [selectedCountry, setSelectedCountry] = useState('');
    const [loading , setLoading] = useState(false)
    const [messageSuccess , setMessageSuccess] = useState()
    const [errSuccess , setErrSuccess] = useState()
    const [units, setUnits] = useState('metric');
    const WEATHER_API_KEY = process.env.REACT_APP_WEATHER_API
    const [tarker,setTarker] = useState(0);
    const handleSinUp = async () =>{
        setTarker(tarker + 1);
        if (tarker < 4){
            try{
                setLoading(true)
                const formData = new FormData();

                formData.append('FirstName', FirstName);
                formData.append('LastName', LastName);
                formData.append('email', email);
                formData.append('phone', phone);
                formData.append('Country', selectedCountry);
                formData.append('password', password);

                const response = await axios.post(process.env.REACT_APP_SERVER + '/auth/signup' , formData , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language' : localStorage.getItem('language')
                    },
                })

                if(response.data){
                    setMessageSuccess(response.data.message)
                }
            } catch(error) {
                console.error(error)
                setErrSuccess(error.response.data.error)
            } finally{
                setLoading(false)
            }
        }
        else
        return;
    }

  // err du champ
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [geoLoaction, setGeoLoaction] = useState({long:0,lat:0});
  const [passwordError, setPasswordError] = useState('');

  //Get Email Value
  const onChangeEmail = (e) => {
    setEmail(e.target.value)
    if (!/^\S+@\S+\.\S+$/.test(email)) {
        setEmailError(t("SignUp.EmailErr"));
    } else {
        setEmailError('');
    }
  }
    //Get Phone Value
    const onChangePhone = (p) => {
        setPhone(p)
        if (!/^\+?\d{1,4}?\s?\d{1,15}$/.test(phone)) {
            setPhoneError(t("SignUp.PhoneErr"));
        } else {
            setPhoneError('');
        }
    }
    //Get Password Value
    const onChangePassword = (p) => {
        setPassword(p.target.value)
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*()_+}{|":?><,./;'[\]\\`~=-]{8,}$/.test(password)) {
            setPasswordError(t("SignUp.PasswordErr"));
        } else {
            setPasswordError('');
        }
    }
    const getGeoLoaction = () => {
        navigator.geolocation.getCurrentPosition((p) => {
        setGeoLoaction (
            {
                long: p.coords.longitude,
                lat: p.coords.latitude,
            }
        )
        })
    }


    //fetsh all coutry
    const  getData = async () => {
        if (geoLoaction)
        await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${geoLoaction.lat}&units=${units}&lon=${geoLoaction.long}&appid=${WEATHER_API_KEY}`)
        .then(response => response.json())
        .then(json => {
            const {sys} = json
            setCgeo(sys.country);
            setSelectedCountry(sys.country)
        })
    }

    useEffect (() => {
        getData();
    },[geoLoaction])

    useEffect ( () => {
        if (hasGeoLocation())
        getGeoLoaction();
    },[])
    const hasGeoLocation = () => {
        return navigator.geolocation;
    }

    // Translation
    const { t } = useTranslation("global");

    const errorMessages = [ phoneError , emailError , passwordError ];

  return (
   <>
      {
        tarker === 4 ?
        <div className="flex justify-center items-center h-screen">
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Holy smokes!</strong>
            <span className="block xl:inline">you are blocked !!.</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>
            </div>
        </div>
        :
        <div className='flex h-screen md:items-center mb-20 md:mb-0 lg:mb-0'>
        <div className='lg:w-1/2 md:w-full my-20 mx-auto w-full px-5 md:px-[70px] lg:px-[40px]'>
            <div className='text-center'>
                <Link className='text-3xl ' to={'/'}>{process.env.REACT_APP_NAME}</Link>
            </div>
            <div className='text-start mt-10'>
                <h1 className='text-xl text-black'>{t("SignUp.title")}</h1>
                <p className='text-slate-500 text-lg'>{t("SignUp.description")}</p>
            </div>
            {/* Warning message */}
            {
                (emailError || passwordError || phoneError) && (
                <ul className='list-disc list-inside'>
                    {errorMessages && <Warning  messages={errorMessages} />}
                </ul>
                )
            }
            {/* Success message */}
            {
                messageSuccess && (
                <Success message={messageSuccess} />
                )
            }
            {/* Err message */}
            {
                errSuccess && (
                <Error className='my-2' message={errSuccess} />
                )
            }
            <div className='flex flex-col md:flex-row justify-start my-2 mt-5'>
                <div className='md:w-1/2 flex flex-col'>
                    <label>{t("SignUp.FirstName")}</label>
                    <input type="text" className='py-1.5 px-4 border-2 border-MybgHover rounded-md' placeholder='John' required value={FirstName} onChange={(f)=>{setFirstName(f.target.value)}}/>
                </div>
                <div className='md:w-1/2 flex flex-col md:ml-1'>
                    <label>{t("SignUp.LastName")}</label>
                    <input type="text" className='py-1.5 px-4 border-2 border-MybgHover rounded-md' placeholder='Doe' value={LastName} onChange={(l)=>{setLastName(l.target.value)}}/>
                </div>
            </div>
            <div className='flex flex-col md:flex-row justify-start my-2'>
                <div className='md:w-1/2 flex flex-col'>
                    <label>{t("SignUp.Phone")}</label>
                    <PhoneInput
                        value={phone}
                        country={cgeo ? cgeo.toLowerCase() : ''}
                        onChange = {onChangePhone}
                        inputProps={{
                            required: true,
                            className : 'py-1.5 px-20 w-full border-2 border-MybgHover rounded-md'
                        }}
                    />
                </div>
                <div className='md:w-1/2 flex flex-col md:ml-1'>
                    <label>{t("SignUp.Country")}</label>
                    <input type="text" className='py-1.5 px-4 border-2 border-MybgHover rounded-md' placeholder='Contry' value={selectedCountry} onChange={(c)=>{setSelectedCountry(c.target.value)}}/>
                </div>
            </div>
            <div className='flex justify-start flex-col'>
                <label>Email</label>
                <input type="text" className='py-1.5 px-4 border-2 border-MybgHover rounded-md' placeholder='name@gamil.com' value={email} onChange={onChangeEmail}/>
            </div>
            <div className='flex relative justify-start flex-col my-2'>
                <label>{t("SignUp.Password")}</label>
                <input type={showPassword ? 'text' : 'password'} className='py-1.5 px-4 border-2 border-MybgHover rounded-md' placeholder='********' value={password} onChange={onChangePassword}/>
                    <span className="absolute top-[28px] right-2 cursor-pointer" onClick={togglePasswordVisibilty}>
                    {showPassword ? <Hide /> : <Show />}
                    </span>
            </div>
            <div className='flex justify-start flex-col my-2'>
                <button onClick={handleSinUp}  className={`py-3 transition-all duration-500 rounded-md text-myColor
                    ${!email || !password || !FirstName || !LastName || !selectedCountry || !phone ? 'bg-MybgHover' : 'bg-Mybg hover:bg-MybgHover' }`}
                    disabled={(!email || !password || !FirstName || !LastName || !selectedCountry || !phone ? 1 : 0) && !tarker}>
                    {loading ? <l-squircle size="20"stroke="5"stroke-length="0.15"bg-opacity="0.1"speed="0.9" color="white" ></l-squircle> : t('SignUp.title')}
                </button>
            </div>
            <div className='flex justify-start flex-col my-2'>
                <p className='text-slate-500'>{t("SignUp.question")}&nbsp;<Link to={'/signIn'} className='font-medium text-black'>{t("SignUp.SignInLink")}</Link></p>
            </div>
        </div>
        <div className='lg:w-1/2 hidden lg:block'>
            <Background>
                <h1 className='text-white text-2xl mx-10 flex text-center justify-center items-center h-screen'>
                    {t("SignUp.description")}
                </h1>
            </Background>
        </div>
      </div>
      }
   </>
  )
}

export default SignUp