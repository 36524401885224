import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../Assets/Loading/Loading';
import LoadingImg from '../../Assets/Pics/loading_img.png';
import Vide from '../../Assets/Pics/Vide.png'
import ConfirmeDeleteUser from './BanneUser'
import DetailsUser from './DetailsUser';
import Table from './Responsive/Table'
import AuthorizeUser from '../../Assets/Icons/AuthorizeUser'
import Banned from '../../Assets/Icons/Banned';
import Send from '../../Assets/Icons/Send';
import Update from '../../Assets/Icons/Update';
import Details from '../../Assets/Icons/Details';
import Search from '../../Assets/Icons/Search';
import UpdateUser from './UpdateUser';
import ConfirmResetPassword from './ConfirmResetPassword';

const Users = () => {
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(false);
    const token_admin = useSelector((state) => state.admin.token_admin);
    const [refresh, setRefesh] = useState(0);
    const [searchText, setSearchText] = useState('');

    const refereshData = async () => {
        await setRefesh(refresh + 1);
    };    

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await axios.get(process.env.REACT_APP_SERVER + '/user/all-user', {
                headers: {
                    'Authorization': `Bearer ${token_admin}`,
                    'Accept-Language' : localStorage.getItem('language')
                }
            });
            if (response.data) {            
                setUsers(response.data.users);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };    

    useEffect(() => {
        fetchUsers();

        const intervalId = setInterval(() => {
            fetchUsers();
        }, 3 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [refresh]);

    //Modal confirm user
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [fisrtName , setFirstName] = useState()
    const [lastName , setLastName] = useState()
    const [userId , setUserId] = useState()

    const openConfirmation = (fisrtName , lastName , id) => {
        setFirstName(fisrtName)
        setLastName(lastName)
        setUserId(id)
        setConfirmationOpen(true);
    };
    
    const closeConfirmation = () => {
        setConfirmationOpen(false);
    };
    
    const handleConfirm = () => {
        closeConfirmation();
        refereshData()
    };

    //Model Details
    const [isDetailOpen , setIsDetailOpen] = useState(false)
    const [detailFisrtName , setDetailFirstName] = useState()
    const [detailLastName , setDetailLastName] = useState()
    const [detailCountry , setDetailCountry] = useState()
    const [detailPhone , setDetailPhone] = useState()
    const [detailEmail , setDetailEmail] = useState()
    const [detailCountSub , setDetailCountSub] = useState()
    const [detailConfirmed , setDetailConfirmed] = useState()

    const openDetail = (fisrtName , lastName , country , phone , email , countSub , confirmed) => {
        setDetailFirstName(fisrtName)
        setDetailLastName(lastName)
        setDetailCountry(country)
        setDetailPhone(phone)
        setDetailEmail(email)
        setDetailCountSub(countSub)
        setIsDetailOpen(true);
        setDetailConfirmed(confirmed)
    };
    
    const closeDetail = () => {
        setIsDetailOpen(false);
    };

    //Modal update user
    const [isUpdateUserOpen , setUpdateUserOpen] = useState(false);

    const openUpdateUser = (fisrtName , lastName , id) => {
        setFirstName(fisrtName)
        setLastName(lastName)
        setUserId(id)
        setUpdateUserOpen(true);
    };
        
    const closeUpdateUser = () => {
        setUpdateUserOpen(false);
    };

    //Authorise from banned to authorised
    const authorizeUser = async (id) => {
        try{
            const response = await axios.put(process.env.REACT_APP_SERVER + `/user/authorize/${id}`, {} ,{
                headers: {
                  'Authorization': `Bearer ${token_admin}`,
                  'Accept-Language' : localStorage.getItem('language')
                }
            })
            if(response.data){
                fetchUsers();
            }
        } catch (error) {
            console.error(error)
        }
    }

    // Confirm reset password
    const [isConfirmResetOpen , setConfirmationResetOpen] = useState(false)
    const openConfirmationReset = ( id ) => {
        setUserId(id)
        setConfirmationResetOpen(true)
    }
    const closeConfirmationReset = () => {
        setConfirmationResetOpen(false)
    }

    const filteredData = users
    ? users.filter((user) =>
        user.FirstName.toLowerCase().includes(searchText.toLowerCase()) ||
        user.LastName.toLowerCase().includes(searchText.toLowerCase())
      )
    : [];  

    return (
        <div className='flex flex-col mx-2 lg:mx-20'>
            <ConfirmResetPassword isOpen={isConfirmResetOpen} onClose={closeConfirmationReset} userId={userId} />
            <ConfirmeDeleteUser isOpen={isConfirmationOpen} onClose={closeConfirmation} onConfirm={handleConfirm} id={userId} fisrtName={fisrtName} lastName={lastName}/>
            <DetailsUser isOpen={isDetailOpen} onClose={closeDetail} fisrtName={detailFisrtName} lastName={detailLastName} country={detailCountry} phone={detailPhone} email={detailEmail} countSubscription={detailCountSub} confirmed={detailConfirmed}/>
            <UpdateUser isOpen={isUpdateUserOpen} onClose={closeUpdateUser} userId={userId} FistName={fisrtName} LastName={lastName} refereshData={refereshData}/>
            <div className='flex md:items-start items-center mt-10 flex-col text-center md:text-start'>
                <h1 className='text-2xl text-start'>Users</h1>
                <p className='text-slate-500'>User Management Interface View and Delete User Details.</p>
            </div>
            <div className='flex my-10 flex-wrap md:hidden lg:hidden'> 
            {/* block */}
                <div className="relative mx-2 justify-center items-center flex-grow flex-shrink-0">
                    <input
                        type="text"
                        placeholder="Search by First Name or Last Name"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className='my-2 border-2 border-Mybg rounded-md py-2 px-2 w-full pl-8 shadow-md' 
                    />
                    <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                        <span className='text-Mybg'>
                            <Search/>
                        </span>
                    </div>
                </div>
                {loading ? (
                    <>
                        <Loading />
                        <div className='flex justify-center items-center mx-auto'>
                            <div className='h-[300px] w-[300px]'>
                                <img src={LoadingImg} alt='Loading' className='h-full w-full object-contain' />
                            </div>
                        </div>
                    </>
                ) : (
                    filteredData ? (
                        filteredData.map((user) => (
                            <div className='flex py-4 px-4 mx-2 my-1 flex-grow flex-shrink-0 md:w-1/4 w-full flex-col border-2 rounded-md shadow-md transition-all md:hover:scale-105' key={user._id}>
                                <div className='flex items-center'>
                                    <div className='w-1/3 flex justify-start'>
                                        <span className='bg-Mybg text-white rounded-full text-lg w-10 h-10 flex items-center justify-center'>{user.FirstName[0]}</span>
                                    </div>
                                    <div className='w-1/3 flex justify-center'>
                                        <h1>{user.FirstName}&nbsp;{user.LastName}</h1>
                                    </div>
                                    <div className='w-1/3 flex justify-end'>
                                        <span>{user.confirmed ? <span className='text-green-500'>Confirmed</span> : <span className='text-red-500'>Not Confirmed</span>}</span>
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    <h1>{user.created.split("T")[0]}</h1>
                                </div>
                                <div className='flex justify-end mt-2'>
                                    {
                                        user.banned ? (
                                            <button onClick={()=>{authorizeUser(user._id)}} className='bg-lime-500 text-white transition-all hover:bg-lime-400 rounded-md py-2 px-2'>
                                                <AuthorizeUser />
                                            </button>
                                        ) : (
                                            <button onClick={()=>{openConfirmation(user.FirstName , user.LastName , user._id)}} className='bg-red-500 text-white transition-all hover:bg-red-400 rounded-md py-2 px-2'>
                                                <Banned />
                                            </button>
                                        )
                                    }
                                    <button onClick={()=>{openDetail(user.FirstName , user.LastName , user.Country , user.phone , user.email , user.subscriptionCount , user.confirmed)}}  className='bg-blue-500 mx-1 text-white transition-all hover:bg-blue-400 rounded-md py-2 px-2'>
                                        <Details />
                                    </button>
                                    <button onClick={()=>{openUpdateUser(user.FirstName , user.LastName , user._id)}}  className='bg-yellow-500 mx-1 text-white transition-all hover:bg-yellow-400 rounded-md py-2 px-2'>
                                        <Update/>
                                    </button>
                                    <button onClick={()=>{openConfirmationReset(user._id)}}  className='bg-green-500 mx-1 text-white transition-all hover:bg-green-400 rounded-md py-2 px-2'>
                                        <Send/>
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='flex justify-center items-center h-[300px] w-[300px] mx-auto'>
                            <img src={Vide} alt='Vide' className='h-full w-full object-contain' />
                        </div>
                    )
                )}
            </div>
            <div className='hidden md:block lg:block'>
                <Table />
            </div>
        </div>
    );
};

export default Users;
